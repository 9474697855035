import { useRef } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import PulseLoader from 'react-spinners/PulseLoader';

import { Modal, InputImage, FormInput, Label, Button } from '@component';
import { useServices } from '@services';
import { collection, productPath } from './constants';

export const Item = ({ item, close, saved }) => {
  const { db } = useServices();

  const validate = values => {
    const errors = {};
    if (!values.packagingName) {
      errors.packagingName = 'Required';
    }
    if (!values.code) {
      errors.code = 'Required';
    }
    if (!values.description) {
      errors.description = 'Required';
    }

    return errors;
  };

  const formikRef = useRef();

  return (
    <Modal
      visible={!!item}
      onClose={close}
      title={item && item.id ? 'View / Edit Packaging' : 'Add new Packaging'}
      className="text-black"
    >
      <Formik
        innerRef={formikRef}
        initialValues={item}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          db.save(collection, values)
            .then(() => {
              setSubmitting(false);
              toast.success('Packaging saved successfully');
              saved();
              close();
            })
            .catch(() => {
              toast.error('An unknown error occured');
            });
        }}
      >
        {({ values, isSubmitting }) => (
          <>
            <Form>
              <div className="md:flex space-x-4">
                <div className="md:w-6/12 space-y-4">
                  <div className="flex space-x-2">
                    <div className="w-3/12">
                      <Label htmlFor="code">Code *</Label>
                      <FormInput name="code"></FormInput>
                    </div>
                    <div className="w-9/12">
                      <Label htmlFor="packagingName">Name *</Label>
                      <FormInput name="packagingName"></FormInput>
                    </div>
                  </div>

                  <div>
                    <Label htmlFor="description">Description *</Label>
                    <FormInput as="textarea" name="description" rows="5"></FormInput>
                  </div>
                </div>
                <div className="md:w-6/12 space-y-4">
                  <div>
                    <label htmlFor="productUrl">Product Image</label>
                    <InputImage
                      imageUrl={values.productUrl}
                      storagePath={productPath}
                      onChange={url => formikRef.current.setFieldValue('productUrl', url)}
                      height="350px"
                    ></InputImage>
                  </div>
                </div>
              </div>

              <div className="flex justify-end">
                <Button type="submit" disabled={!!isSubmitting} className="btn btn-brand btn-elevate btn-icon-sm mt-12">
                  {isSubmitting ? <PulseLoader color="white" size={10}></PulseLoader> : 'Submit'}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
};
