import { useLocation } from 'react-router-dom';
import { split, get } from 'lodash';

import { SubHeader } from '@component';
import { IndustryOverviewList } from './List';
import { pageName } from './constants';

export const IndustryOverview = () => {
  const location = useLocation();
  const type = get(split(location.pathname, '/'), '2');
  return (
    <>
      <SubHeader title={pageName[type]}></SubHeader>
      <div className="m-4 mx-4">
        <IndustryOverviewList type={type} />
      </div>
    </>
  );
};
