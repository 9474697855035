import * as React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';

import Loader from '../../component/common/Loader';
import SubHeader from '../../component/common/SubHeader';
import CONSTANT from '../../constant';
import { db } from '../../firebase/index';
import { onChange } from '../../utils';

class TermCondition extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showLoader: false,
      id: undefined,
      body: { name: 'body', value: '', error: '', isRequired: true },
    };
  }

  componentDidMount() {
    this.loadList();
  }

  public render() {
    const { showLoader } = this.state;
    return (
      <React.Fragment>
        {showLoader && <Loader />}
        <SubHeader headerTitle="CMS Pages" seperatorTitle="" />
        {this.renderBody()}
      </React.Fragment>
    );
  }

  renderBody = () => {
    const { body } = this.state;
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="row">
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Terms & Conditions</h3>
              </div>
            </div>

            <div className="kt-portlet__body">
              <div className="col-md-12">
                <div className="form-group">
                  <CKEditor
                    editor={ClassicEditor}
                    data={body.value}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData();
                      onChange(this, body.name, data);
                    }}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4"></div>
                <div className="col-md-4"></div>
                <div className="col-md-2"></div>
                <div className="col-md-2 pr-4">
                  <button type="button" className="btn btn-sm btn-primary btn-block" onClick={this.onPublish}>
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  private onChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    onChange(this, name, value);
  };

  onSaveDraft = (model: any) => {};

  loadList = async () => {
    this.setState({ showLoader: true });
    const userQuerySnapshot = await db.collection(CONSTANT.collection.cmsTermCondition).get();
    const list: any[] = [];
    userQuerySnapshot.forEach((doc: any) => {
      let data = doc.data();
      data.id = doc.id;
      list.push(data);
    });
    if (list.length > 0) {
      this.setState({
        body: { ...this.state.body, value: list[0].content },
        id: list[0].id,
      });
    }
    this.setState({ showLoader: false });
  };

  onPublish = async (e: any) => {
    e.preventDefault();
    const { id, body } = this.state;
    let newObjModel: any = {
      content: body.value,
      modifiedOn: moment().unix(),
      available: true,
    };
    this.setState({ showLoader: true });
    if (id) {
      delete newObjModel.id;
      await db.collection(CONSTANT.collection.cmsTermCondition).doc(`${id}`).set(newObjModel, { merge: true });
    } else {
      await db.collection(CONSTANT.collection.cmsTermCondition).add(newObjModel);
    }
    this.setState({ showLoader: false });
  };
}

export default TermCondition;
