import ThinkDigitalLogo from '@assets/images/ThinkDigitalLogo.png';

export const Footer = ({ className }) => {
  return (
    <div className={`${className} flex items-center text-white`}>
      <div className="w-6/12 p-4">
        Copyright © {new Date().getFullYear()} <b>AUS-MEAT</b>. All rights reserved.
      </div>
      <div className="w-6/12 p-4 flex items-center justify-end">
        Powered by
        <img alt="Logo" src={ThinkDigitalLogo} style={{ height: 30 }} className="ml-2" />
      </div>
    </div>
  );
};
