import React from 'react';

export const SubHeader = props => {
  return (
    <div className="w-full flex items-center bg-white p-4 mb-16">
      <h3 className="text-xl font-medium text-gray-900 mr-4">{props.title}</h3>
      {props.seperatorTitle && (
        <React.Fragment>
          <span className="mr-4">|</span>
          <div>
            <span className="text-gray-400 font-medium text-lg">{props.seperatorTitle}</span>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
