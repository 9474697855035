import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_ID,
  apiKey: process.env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = firebase.initializeApp(firebaseConfig);
// const appCheck = firebase.appCheck();
// appCheck.activate(process.env.APPCHECK_RECAPTCHA_SECRET_KEY);

const storageBucketPath = `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_ID}`;

export const db = firebase.firestore(app);
export const auth = firebase.auth(app);
export const storage = app.storage(storageBucketPath);
export const functions = app.functions('australia-southeast1');

// functions.useEmulator('localhost', 5001);
// auth.useEmulator('http://localhost:9099');
// db.useEmulator('localhost', 8080);
// storage.useEmulator('localhost', 9199);

const Timestamp = firebase.firestore.Timestamp;

db.save = (collection, item) => {
  const timestamp = Timestamp.fromDate(new Date());
  if (item.id) {
    const _item = { modifiedOn: timestamp, ...item };
    return db.collection(collection).doc(item.id).set(_item, { merge: true });
  }
  const _item = { modifiedOn: timestamp, isActive: true, createdOn: timestamp, ...item };
  return db.collection(collection).add(_item);
};

db.list = collection => {
  return db
    .collection(collection)
    .where('isActive', '==', true)
    .orderBy('modifiedOn', 'asc')
    .get()
    .then(result => {
      return result.docs.map(doc => {
        return { ...doc.data(), id: doc.id };
      });
    });
};
