import { useTable, usePagination } from 'react-table';
import { style } from './Table.module.scss';

import { TertiaryButton } from '@component';

export const Table = function Table({ columns, data, actions }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <div className={style}>
      <table {...getTableProps()} className="w-full">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className="text-black font-semi-bold">
                  {column.render('Header')}{' '}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
                {actions && <td>{actions(row)}</td>}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination mt-4 flex justify-center">
        <TertiaryButton className="mr-4 text-primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          First
        </TertiaryButton>
        <TertiaryButton className="mr-4 text-primary" onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </TertiaryButton>
        <div className="mr-4 text-primary-500">
          Page {pageIndex + 1} of {pageCount}
        </div>
        <TertiaryButton className="mr-4 text-primary" onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </TertiaryButton>
        <TertiaryButton className="text-primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          Last
        </TertiaryButton>
        {/* <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span> */}
        {/* <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select> */}
      </div>
    </div>
  );
};
