export const collection = 'meatcut';
export const animalCollection = 'cms_category_animal';
export const levelOneCollection = 'cms_category_level1';
export const levelTwoCollection = 'cms_category_level2';
export const levelThreeCollection = 'cms_category_level3';

export const fileDirectory = 'product_image';
export const texturePath = 'texture';
export const carcasePath = 'carcase';
export const skeletonPath = 'skelton';
export const modelPath = 'model3d';
