import ThinkDigitalLogo from '@assets/images/ThinkDigitalLogo.png';

const ComponentName = () => {
  const dt = new Date();
  return (
    <div className="kt-footer  kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
      <div className="kt-container  kt-container--fluid ">
        <div className="kt-footer__copyright">
          Copyright&nbsp;©&nbsp;{dt.getFullYear()}&nbsp;{' '}
          <b>
            <span className="kt-link">AUS-MEAT</span>
          </b>
          . All rights reserved.
        </div>
        <div className="flex items-center">
          Powered by
          <img alt="Logo" src={ThinkDigitalLogo} style={{ height: 30 }} className="ml-2" />
        </div>
      </div>
    </div>
  );
};

export default ComponentName;
