import { useRef } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import { Modal, FormInput, Label, AsyncButton } from '@component';
import { useServices } from '@services';
import { collection } from './constants';

export const Item = ({ item, close, saved }) => {
  const { db } = useServices();

  const validate = values => {
    const errors = {};
    if (!values.title) {
      errors.title = 'Required';
    }
    if (!values.text) {
      errors.text = 'Required';
    }

    return errors;
  };

  const formikRef = useRef();

  return (
    <Modal
      visible={!!item}
      onClose={close}
      title={item && item.id ? 'View / Edit Checklist' : 'Add new Checklist'}
      className="text-black"
    >
      <Formik
        innerRef={formikRef}
        initialValues={item}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          db.save(collection, values)
            .then(() => {
              setSubmitting(false);
              toast.success('User saved successfully');
              saved();
              close();
            })
            .catch(() => {
              toast.error('An unknown error occured');
            });
        }}
      >
        {({ values, isSubmitting }) => (
          <>
            <Form>
              <div>
                <Label htmlFor="title">Title *</Label>
                <FormInput name="title"></FormInput>
              </div>

              <div>
                <Label htmlFor="text" className="mt-4">
                  Text *
                </Label>
                <FormInput name="text" as="textarea" type="text" minHeight="250px"></FormInput>
              </div>

              <div>
                <Label htmlFor="order" className="mt-4">
                  Order
                </Label>
                <FormInput name="order" type="number"></FormInput>
              </div>

              <div className="flex justify-end">
                <AsyncButton type="submit" className="btn btn-brand btn-elevate btn-icon-sm mt-4">
                  Submit
                </AsyncButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
};
