import React from 'react';

const ComponentName = (props: any) => {
  return (
    <div className="w-full flex bg-white p-4 mb-16">
      <div>
        <h3 className="text-xl font-medium text-gray-900">{props.headerTitle}</h3>
        {props.seperatorTitle && (
          <React.Fragment>
            <span></span>
            <div>
              <span>{props.seperatorTitle}</span>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ComponentName;
