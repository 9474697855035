import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { split, get, startCase, replace } from 'lodash';

import { SubHeader } from '@component';
import { AnimalList } from './AnimalType/List';
import { LevelOneList } from './LevelOne/List';
import { LevelTwoList } from './LevelTwo/List';
import { MuscleCode } from './MuscleCode/List';
import { CarcaseList } from './Carcase/List';
import { CarcaseSubcategoryList } from './CarcaseSub/List';

const background = 'bg-white p-4 rounded-md mt-4';

export const CategoryManagement = () => {
  const location = useLocation();
  const type = get(split(location.pathname, '/'), '2');
  const subHeaderTitle = useMemo(() => startCase(replace(type, /-/g, ' ')), [type]);
  return (
    <>
      <SubHeader title={subHeaderTitle}></SubHeader>
      <div className="mx-4">
        {type === 'animal-type' && <AnimalList className={background} />}
        {type === 'muscle' && <MuscleCode className={background} />}
        {type === 'level-1-category' && <LevelOneList className={background} />}
        {type === 'level-2-category' && <LevelTwoList className={background} />}
        {type === 'carcase-category' && <CarcaseList className={background} />}
        {type === 'carcase-subcategory' && <CarcaseSubcategoryList className={background} />}
      </div>
    </>
  );
};
