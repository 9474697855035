import { toast } from 'react-toastify';

const isolateFile = files => {
  var file = files[0];
  if (!file) {
    toast.error('File is invalid');
    return;
  }
  return file;
};

const timestampFilename = file => {
  const extArray = file.name.split('.');
  const ext = extArray[extArray.length - 1];
  const originalFilename = extArray[0];
  return `${originalFilename}-${new Date().getTime()}.${ext}`;
};

export { isolateFile, timestampFilename };
