import { useState, useCallback, useEffect } from 'react';

import { Card } from '@component';
import { useServices } from '@services';

export const TotalUsers = () => {
  const { functions } = useServices();

  const [loading, setLoading] = useState(false);
  const [usersCount, setUsersCount] = useState(0);

  const getUsersCount = useCallback(() => {
    setLoading(true);
    const getUserListFunction = functions.httpsCallable('api/users/getList');
    getUserListFunction()
      .then(({ data }) => {
        setUsersCount(data?.users?.length || 0);
        setLoading(false);
      })
      .catch(err => console.log('🚀 ~ file: TotalUsers.jsx ~ line 18 ~ getUsersCount ~ err', err));
  }, [functions]);

  useEffect(() => getUsersCount(), [getUsersCount]);

  return (
    <Card loading={loading} className="text-center">
      <div className="mb-4">{usersCount ? usersCount : '-'}</div>
      <div>Total Users</div>
    </Card>
  );
};
