import { Modal as StrapModal, ModalHeader, ModalBody } from 'reactstrap';

export const Modal = ({ title, children, className, backdrop, toggleModal, visible, onClose }) => {
  return (
    <StrapModal isOpen={visible} className={className} toggle={onClose}>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </StrapModal>
  );
};
