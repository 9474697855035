import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useServices } from '@services';

import Logo from '@assets/images/logo_with_grey.png';

export const Sidebar = () => {
  const { auth } = useServices();
  const [subMenu, setSubMenu] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    auth.currentUser.getIdTokenResult().then(token => {
      setRole(token?.claims?.role || '');
    });
  }, [auth]);

  const toggleSubMenu = selected => {
    if (selected === subMenu) {
      setSubMenu('');
      return;
    }
    setSubMenu(selected);
  };

  const bullet = (
    <div className="flex justify-center items-center w-4 h-4">
      <span className="bg-white rounded-full" style={{ width: '4px', height: '4px' }}></span>
    </div>
  );
  const dashIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      className="kt-svg-icon"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <rect fill="#000000" opacity="0.3" x="4" y="4" width="8" height="16"></rect>
        <path
          d="M6,18 L9,18 C9.66666667,18.1143819 10,18.4477153 10,19 C10,19.5522847 9.66666667,19.8856181 9,20 L4,20 L4,15 C4,14.3333333 4.33333333,14 5,14 C5.66666667,14 6,14.3333333 6,15 L6,18 Z M18,18 L18,15 C18.1143819,14.3333333 18.4477153,14 19,14 C19.5522847,14 19.8856181,14.3333333 20,15 L20,20 L15,20 C14.3333333,20 14,19.6666667 14,19 C14,18.3333333 14.3333333,18 15,18 L18,18 Z M18,6 L15,6 C14.3333333,5.88561808 14,5.55228475 14,5 C14,4.44771525 14.3333333,4.11438192 15,4 L20,4 L20,9 C20,9.66666667 19.6666667,10 19,10 C18.3333333,10 18,9.66666667 18,9 L18,6 Z M6,6 L6,9 C5.88561808,9.66666667 5.55228475,10 5,10 C4.44771525,10 4.11438192,9.66666667 4,9 L4,4 L9,4 C9.66666667,4 10,4.33333333 10,5 C10,5.66666667 9.66666667,6 9,6 L6,6 Z"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
      </g>
    </svg>
  );
  const pageIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      className="kt-svg-icon"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24"></polygon>
        <path
          d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
          fill="#000000"
          fill-rule="nonzero"
        ></path>
        <path
          d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
          fill="#000000"
          opacity="0.3"
        ></path>
      </g>
    </svg>
  );

  return (
    <div className="fixed top-0 bottom-0 left-0 bg-sidebar-900 w-full md:w-sidebar hidden md:block">
      <div className="flex p-4">
        <Link to="/">
          <img alt="Logo" src={Logo} style={{ height: 30 }} />
        </Link>
      </div>
      <div className="p-4 mt-12">
        <Link to="/" className="text-sidebar-200 hover:text-white flex items-center">
          {dashIcon}
          <div className="ml-3">Dashboard</div>
        </Link>
        {role === 'admin' && (
          <Link to="/user" className="text-sidebar-200 hover:text-white flex items-center mt-8">
            {pageIcon}
            <div className="ml-3">User Management</div>
          </Link>
        )}

        <button onClick={() => toggleSubMenu('db')} className="flex items-center justify-between mt-8 w-full">
          <div href="/user" className="text-sidebar-200 hover:text-white flex items-center">
            <div>{pageIcon}</div>
            <div className="ml-3">Database Management</div>
          </div>
          <div>
            {subMenu === 'db' ? (
              <i className="kt-menu__ver-arrow la la-angle-down"></i>
            ) : (
              <i className="kt-menu__ver-arrow la la-angle-right"></i>
            )}
          </div>
        </button>

        {subMenu === 'db' && (
          <div className="ml-3">
            <Link to="/database/meatcut" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">Meat Cut</div>
            </Link>
            <Link to="/database/primalcut" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">Primal Cut</div>
            </Link>
            <Link to="/category/muscle" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">Muscle</div>
            </Link>
            <Link to="/database/dentition" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">Dentition</div>
            </Link>
            <Link to="/database/packaging" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">Packaging</div>
            </Link>
            <Link to="/database/checklist" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">Aus Meat Checklist Guide</div>
            </Link>
          </div>
        )}

        <Link to="/pdf-management" className="text-sidebar-200 hover:text-white flex items-center mt-8">
          {pageIcon}
          <div className="ml-3">PDF Management</div>
        </Link>
        {/* <Link to="/cms-pages/category" className="text-sidebar-200 hover:text-white flex items-center mt-8">
          {pageIcon}
          <div className="ml-3">Category Management</div>
        </Link> */}
        <button onClick={() => toggleSubMenu('category')} className="flex items-center justify-between mt-8 w-full">
          <div className="text-sidebar-200 hover:text-white flex items-center">
            <div>{pageIcon}</div>
            <div className="ml-3">Category Management</div>
          </div>
          <div>
            {subMenu === 'category' ? (
              <i className="kt-menu__ver-arrow la la-angle-down"></i>
            ) : (
              <i className="kt-menu__ver-arrow la la-angle-right"></i>
            )}
          </div>
        </button>
        {subMenu === 'category' && (
          <div className="ml-3">
            <Link to="/category/animal-type" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">Animal Type</div>
            </Link>
            <Link to="/category/level-1-category" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">Level 1 Category</div>
            </Link>
            <Link to="/category/level-2-category" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">Level 2 Category</div>
            </Link>
            <Link to="/category/carcase-category" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">Carcase Category</div>
            </Link>
            <Link
              to="/category/carcase-subcategory"
              className="text-sidebar-200 hover:text-white flex items-center mt-8"
            >
              {bullet}
              <div className="ml-3">Carcase Subcategory</div>
            </Link>
          </div>
        )}

        <button onClick={() => toggleSubMenu('cms-pages')} className="flex items-center justify-between mt-8 w-full">
          <div className="text-sidebar-200 hover:text-white flex items-center">
            <div>{pageIcon}</div>
            <div className="ml-3">Industry Overview</div>
          </div>
          <div>
            {subMenu === 'cms-pages' ? (
              <i className="kt-menu__ver-arrow la la-angle-down"></i>
            ) : (
              <i className="kt-menu__ver-arrow la la-angle-right"></i>
            )}
          </div>
        </button>
        {subMenu === 'cms-pages' && (
          <div className="ml-3">
            <Link to="/cms-pages/about" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">About Aus Meat</div>
            </Link>
            <Link to="/cms-pages/food" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">Food Safety</div>
            </Link>
            <Link to="/cms-pages/marketing" className="text-sidebar-200 hover:text-white flex items-center mt-8">
              {bullet}
              <div className="ml-3">Marketing Research & Development</div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
