import { toast } from 'react-toastify';
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useState } from 'react';
import { useServices } from '@services';

import DropzoneImg from './DropzoneImg';
import { Progress } from './Progress';

export const InputImage = ({ imageUrl, storagePath, onChange, className, height }) => {
  const { storage } = useServices();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const isolateFile = files => {
    var file = files[0];
    if (!file) {
      toast.error('File is invalid');
      return;
    }
    return file;
  };

  const timestampFilename = file => {
    const extArray = file.name.split('.');
    const ext = extArray[extArray.length - 1];
    const originalFilename = extArray[0];
    return `${originalFilename}-${new Date().getTime()}.${ext}`;
  };

  const uploadImage = files => {
    setLoading(true);
    const file = isolateFile(files);
    if (!file) {
      toast.error('File not found');
      return;
    }
    const fileName = timestampFilename(file);
    const filePath = `${storagePath}/${fileName}`;

    const uploadTask = storage.ref(filePath).put(file);

    uploadTask.catch(err => {
      console.log('🚀 ~ file: InputImage.jsx ~ line 25 ~ uploadTask ~ err', err);
      toast.error(err);
    });

    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);
      },
      () => {
        toast.error('An unknown error occured');
        setLoading(false);
      },
      () => {
        storage
          .ref(storagePath)
          .child(fileName)
          .getDownloadURL()
          .then(url => {
            setLoading(false);
            onChange(url);
            toast.success('Image upload was successful');
          });
      }
    );
  };

  const onDeleteImage = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to remove the image?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'No, keep it',
    }).then(result => {
      if (result.value) {
        deleteImage();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your image image is safe :)', 'error');
      }
    });
  };

  const deleteImage = () => {
    setLoading(true);
    const imageRef = storage.refFromURL(imageUrl);

    storage
      .ref(storagePath)
      .child(imageRef.name)
      .delete()
      .then(() => {
        onChange('');
      })
      .catch(({ code }) => {
        if (code === 'storage/object-not-found') onChange('');
        else toast.error('An error has occured while removing your image.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <Progress completed={progress}></Progress>;
  }

  return (
    <div className={className}>
      {imageUrl ? (
        <div className="flex flex-col justify-end items-center" style={{ height: height || 'auto' }}>
          <a href={imageUrl} target="_blank" rel="noreferrer">
            <img src={imageUrl} alt="thumbnail" style={{ maxHeight: '300px' }} />
          </a>
          <div className="flex justify-center">
            <button
              onClick={onDeleteImage}
              type="button"
              className="btn btn-secondary btn-sm btn-block kt-mt-10"
              aria-label="Delete Image"
            >
              Remove Image
            </button>
          </div>
        </div>
      ) : (
        <DropzoneImg acceptFile={uploadImage} />
      )}
    </div>
  );
};
