import * as React from 'react';
import 'react-toastify/dist/ReactToastify.css';

import Loader from '../../component/common/Loader';
import CONSTANT from '../../constant';
import { getStorage } from '../../utils';
import { db } from '../../firebase/index';
import defaultProfile from './../../assets/media/users/default.jpg';

class Profile extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: props.match.params.id,
      showLoader: false,
      detail: undefined,
    };
  }

  componentDidMount() {
    const ud = getStorage(CONSTANT.keys.userDetail);
    this.setState({ id: ud.uid }, () => {
      this.loadList();
    });
  }

  public render() {
    const { showLoader } = this.state;
    return (
      <React.Fragment>
        {showLoader && <Loader />}

        {this.renderHeader()}
        {this.renderBody()}
      </React.Fragment>
    );
  }

  renderHeader = () => {
    return (
      <div className="kt-subheader   kt-grid__item" id="kt_subheader">
        <div className="kt-container  kt-container--fluid ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">My Profile</h3>
          </div>
        </div>
      </div>
    );
  };

  renderBody = () => {
    const { detail } = this.state;
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="row">
          <div className="col-xl-8 col-lg-12 col-md-8 col-sm-8">
            <div className="card card-custom gutter-b card-stretch">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="mt-3">
                          <div className="example-preview" id="kt_blockui_content">
                            {detail && detail.profileUrl ? (
                              <img src={detail.profileUrl} className="img-fluid" alt="img" height="200" width="200" />
                            ) : (
                              <img src={defaultProfile} className="img-fluid" alt="img" height="200" width="200" />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-8">
                        <div className="row">
                          <label className="col-4 col-form-label font-weight-bold">Admin Name</label>
                          <label className="col-8 col-form-label">
                            {detail && detail.displayName ? detail.displayName : '-'}
                          </label>
                        </div>
                        <div className="row">
                          <label className="col-4 col-form-label font-weight-bold">Phone no.</label>
                          <label className="col-8 col-form-label">
                            {detail && detail.mobile ? detail.mobile : '-'}
                          </label>
                        </div>
                        <div className="row">
                          <label className="col-4 col-form-label font-weight-bold">Email</label>
                          <label className="col-8 col-form-label">{detail && detail.email ? detail.email : '-'}</label>
                        </div>
                        <div className="row">
                          <label className="col-4 col-form-label font-weight-bold">Password</label>
                          <label className="col-8 col-form-label">{`**********`}</label>
                        </div>
                        <div className="row mt-5 mb-2">
                          <div className="col-md-4">
                            <button
                              onClick={this.onEdit}
                              type="button"
                              className="btn btn-primary btn-sm btn-sm btn-block"
                            >
                              Edit Profile
                            </button>
                          </div>
                          <div className="col-md-4" />
                          <div className="col-md-4">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm btn-sm btn-block"
                              onClick={this.onChangePassword}
                            >
                              Change Password
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  loadList = async () => {
    const { id } = this.state;
    this.setState({ showLoader: true });

    const user = await db.collection(CONSTANT.collection.users).doc(`${id}`).get();
    // if (!user.exists) return handleFailure(res, 'User not found');
    let userData = user.data();

    this.setState({ detail: userData, showLoader: false });
  };

  onEdit = () => {
    const { id } = this.state;
    window.location.href = CONSTANT.url.profileEdit.replace(':id', id);
  };

  onChangePassword = () => {
    const { id } = this.state;
    window.location.href = CONSTANT.url.changePassword.replace(':id', id);
  };
}

export default Profile;
