import { useState, useMemo } from 'react';

import { ModelTable, Button } from '@component';

import { collection } from './constants';
import { Item } from './Item';

export const AnimalList = ({ className }) => {
  const [item, setItem] = useState(null);
  const [updatedOn, setUpdatedOn] = useState(new Date());

  const columns = useMemo(() => [{ Header: 'Name', accessor: 'name' }], []);

  const newItem = {
    name: '',
  };

  return (
    <div className={className}>
      <div className="flex justify-between">
        <h3 className="text-lg font-bold text-black">Animal Type</h3>
        <Button onClick={() => setItem(newItem)}>Add Animal Type</Button>
      </div>
      <ModelTable
        model={{ name: 'Animal Type' }}
        collection={collection}
        columns={columns}
        version={updatedOn}
        onViewRow={row => setItem({ ...newItem.name, ...row })}
        className="mt-4"
      />
      <Item item={item} close={() => setItem(null)} saved={() => setUpdatedOn(new Date())}></Item>
    </div>
  );
};
