import { PublicRouter } from 'public/Router';
import { PortalRouter } from 'Portal/Router';
import { useServices } from 'services';
import { useEffect, useState } from 'react';

export const Router = () => {
  const { auth } = useServices();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setUser(user);
      setLoading(false);
    });
  }, [auth]);

  return (
    <div>
      {!loading && (
        <>
          {!user && <PublicRouter />}
          {user && <PortalRouter />}
        </>
      )}
    </div>
  );
};
