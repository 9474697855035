import { Cookies } from 'react-cookie';
import moment from 'moment';
import CONSTANT from './constant';

export const setCookie = (cookieName: string, token: any) => {
    const cookies = new Cookies();
    cookies.set(cookieName, token, { path: '/' });
};

export const getCookie = (cookieName: string) => {
    const cookie = new Cookies();
    const value = cookie.get(cookieName);
    return value;
};

// export const removeCookie = (cookieName: string) => {
//     const cookie = new Cookies();
//     const value = cookie.remove(cookieName, cookieName, { path: '/' });
//     return value;
// };

export const removeAllCookies = () => {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
        // let cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
};

export const setStorage = (key: string, jsonObj: any) => {
    localStorage.setItem(key, JSON.stringify(jsonObj));
}

export const getStorage = (keyName: string) => {
    const item: any = localStorage.getItem(keyName);
    return JSON.parse(item);
}

export const removeStorage = (keyName: string) => {
    localStorage.removeItem(keyName);
}

export const removeAllLocalStorge = () => {
    localStorage.clear();
}

export const getAuthHeader = () => {
    const token = getStorage(CONSTANT.keys.token);
    const header = {
        headers: { Authorization: "Bearer " + token }
    };
    return header;
}

export const getUploadFileHeader = () => {
    const token = getStorage(CONSTANT.keys.token);
    const header = {
        headers: {
            Authorization: "Bearer " + token,
            'content-type': 'multipart/form-data/json'
        }
    };
    return header;
}

const onChange = (context: any, name: string, newValue: any, callback?: any) => {
    context.setState({ [name]: { ...context.state[name], value: newValue } }, callback && callback);
}

export { onChange, onChange as updateState}

export const onArrayChange = (context: any, name: string, value: any, callback?: any) => {
    const updatedValue = context.state[name].value.concat(value);
    context.setState({ [name]: { ...context.state[name], value: updatedValue } }, callback && callback);
}

export const setOptions = (context: any, name: string, value: any, callback?: any) => {
    context.setState({ [name]: { ...context.state[name], options: value } }, callback && callback);
}

export const clearOptions = (context: any, name: string, value: any, callback?: any) => {
    context.setState({ [name]: { ...context.state[name], options: [] } }, callback && callback);
}

export const setError = (context: any, name: string, error: string, callback?: any) => {
    context.setState({ [name]: { ...context.state[name], error } }, callback && callback);
}

export const setRequired = (context: any, name: string, newIsRequired: any, callback?: any) => {
    context.setState({ [name]: { ...context.state[name], isRequired: newIsRequired } }, callback && callback);
}

export const setShow = (context: any, name: string, newShow: any, callback?: any) => {
    context.setState({ [name]: { ...context.state[name], show: newShow } }, callback && callback);
}

export const validateForm = (context: any) => {
    const st = JSON.parse(JSON.stringify(context.state));
    let status = true;
    for (let key in st) {
        if (st.hasOwnProperty(key) && st[key]) {
            const name = st[key].name;
            const isRequired = st[key].isRequired;
            const value = st[key].value;

            if (isRequired && value.length === 0) {
                status = false;
                setError(context, name, 'This field is required');
            } else if (isRequired && value.length > 0) {
                setError(context, name, '');
            }
        }
    }
    return status;
}

export const getDateParts = (input: string) => {
    // utcOffset(330).
    // const mDate = moment.unix(input).subtract(5, "hours").subtract(30, "minutes");
    const mDate = moment(input);
    return {
        date: mDate.format("DD"),
        month: mDate.format("MM"),
        monthName: mDate.format("MMM"),
        year: mDate.format("YYYY"),
        hour: mDate.format("h"),
        minute: mDate.format("mm"),
        seconds: mDate.format("ss"),
        amOrpm: mDate.format("A"),
        relativeTime: mDate.fromNow()
    };
};

export const getDatePartsUnix = (input: number) => {
    const mDate = moment.unix(input);
    return {
        date: mDate.format("DD"),
        month: mDate.format("MM"),
        monthName: mDate.format("MMM"),
        year: mDate.format("YYYY"),
        hour: mDate.format("h"),
        minute: mDate.format("mm"),
        seconds: mDate.format("ss"),
        amOrpm: mDate.format("A"),
        relativeTime: mDate.fromNow()
    };
};

export const toLowercaseAndRemoveSpecialChar = (name: string) => {
    const formattedName = name.toLowerCase().replace(/ /g, '-');
    return formattedName;
}

export const removeSpaceAndAddHyphen = (param: string) => {
    const formattedName = param.toLowerCase().replace(/ /g, '-');
    return formattedName;
}

export const replaceForwardSlashWithComma = (param: string) => {
    const formattedName = param.toLowerCase().replace(/\//g, ',');
    return formattedName;
}

export const removeHyphenAndAddSpace = (param: string) => {
    const formattedName = param.toLowerCase().replace(/-/g, ' ');
    return formattedName;
}

export const removeCommas = (param: string) => {
    const formattedName = param.toLowerCase().replace(/,/g, '');
    return formattedName;
}

export const toTitleCase = (str: string) => {
    return str.replace(
        /\w\S*/g,
        (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export const isEmpty = (input: any) => {
    const dataType = typeof input;
    if (input === null || input === undefined) {
        return true;
    } else if (dataType === "string") {
        const parsedValue = input.replace(/ /g, "").replace(/(?:\r\n|\r|\n)/g, '');;
        if (parsedValue.length === 0) {
            return true;
        }
    } else if (dataType === "object") {
        return Object.keys(input).length === 0;
    }
    return false;
}

export const replaceLineBreakWithBR = (input: any) => {
    return input.replace(/(?:\r\n|\r|\n)/g, '<br/>');
}

export const setLocalStorageItem = (key: string, jsonObj: any) => {
    localStorage.setItem(key, JSON.stringify(jsonObj));
}

export const getLocalStorageItem = (keyName: string) => {
    const item: any = localStorage.getItem(keyName);
    return JSON.parse(item);
}

export const setSessionStorageItem = (key: string, jsonObj: any) => {
    sessionStorage.setItem(key, JSON.stringify(jsonObj));
}

export const getSessionStorageItem = (keyName: string) => {
    const item: any = sessionStorage.getItem(keyName);
    return JSON.parse(item);
}


export const titleCase = (str: string) => {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}

export const getRelativeTime = (date: any) => {
    const d = new Date(date * 1000);
    return moment(d).fromNow();
}

export const isValid24HourTime = (timeStr: any) => (timeStr.search(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/) !== -1)

export const isValidDateFormat = (dateString: any) => (dateString.search(/^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/) !== -1);

export const isValidDate = (dateString: any) => {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false;  // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString;
}

export const clearUnwanted = (model: any) => {
    for (var key in { ...model }) {
        if (model[key] === null)
            delete model[key]
    }
    return model
}

export function isEquivalent(a: any, b: any, skip: any) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];
        if (propName === skip)
            continue;
        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

export const getBrowserDetail = () => {
    let nVer = navigator.appVersion;
    let nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) !== -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) !== -1)
            fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() === browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) !== -1)
        fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) !== -1)
        fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
    }

    return {
        nVer, nAgt, browserName, fullVersion, majorVersion, nameOffset, verOffset, ix
    }
}

export const getOSDetail = () => {
    let OSName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";

    return OSName;
}

export const formatBytes = (a: any, b = 2) => {
    if (0 === a) return "0 Bytes";
    const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
    return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
}
