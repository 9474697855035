import { useState, useMemo } from 'react';

import { ModelTable, Button, SubHeader } from '@component';

import { collection } from './constants';
import { Item } from './Item';

export const PackagingList = () => {
  const [item, setItem] = useState(null);
  const [updatedOn, setUpdatedOn] = useState(new Date());

  const newItem = {
    code: '',
    packagingName: '',
    description: '',
    productUrl: '',
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Image',
        accessor: ({ productUrl }) => {
          if (!productUrl) return '';
          return (
            <div className="flex justify-center">
              <img src={productUrl} alt="Visual representation" height="60" width="90" />
            </div>
          );
        },
      },
      { Header: 'Code', accessor: 'code' },
      { Header: 'Name', accessor: 'packagingName' },
    ],
    []
  );

  return (
    <>
      <SubHeader title="Database Management" seperatorTitle="Packaging" />
      <div className="bg-white p-4 m-4 rounded-md mx-4 items-center">
        <div className="flex justify-end">
          <Button onClick={() => setItem(newItem)}>Add New Packaging</Button>
        </div>
        <ModelTable
          model={{ name: 'Packaging' }}
          collection={collection}
          columns={columns}
          version={updatedOn}
          onViewRow={row => setItem({ ...newItem, ...row })}
          className="mt-4"
        />
        <Item item={item} close={() => setItem(null)} saved={() => setUpdatedOn(new Date())}></Item>
      </div>
    </>
  );
};
