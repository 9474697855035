import { createContext, useContext } from 'react';
import { db, auth, storage, functions } from './firebase';

const _services = { db, auth, storage, functions };
const DecouplerContext = createContext(_services);

export const ServiceProvider = ({ services, children }) => {
  return <DecouplerContext.Provider value={services || _services}>{children}</DecouplerContext.Provider>;
};

export const useServices = () => {
  return useContext(DecouplerContext);
};
