import { useState, useMemo } from 'react';

import { useServices } from '@services';
import { ModelTable, Button, SubHeader } from '@component';

import { collection } from './constants';
import { Item } from './Item';

export const PrimalCutList = () => {
  const { db } = useServices();

  const [item, setItem] = useState(null);
  const [updatedOn, setUpdatedOn] = useState(new Date());
  const [filter, setFilter] = useState('');

  const newItem = {
    meatcut: '', // muscle name
    meatcutCode: '', // muscle code
    categoryId: '',
    modelUrl: '',
    photoRefUrl: '',
    carcasePercentage: '',
    view: '',
    tag: '',
    weightRange1: '',
    weightRange2: '',
    weightRange3: '',
    weightRange4: '',
    muscleIds: [],
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Photo Reference',
        accessor: ({ photoRefUrl }) => {
          if (!photoRefUrl) return '';
          return (
            <div className="flex justify-center">
              <img src={photoRefUrl} alt="Muscle" height="60" width="90" />
            </div>
          );
        },
      },
      { Header: 'Name', accessor: 'meatcut' },
      { Header: 'Code', accessor: 'meatcutCode' },
      { Header: 'View', accessor: 'view' },
      { Header: 'Tag', accessor: 'tag' },
    ],
    []
  );

  return (
    <>
      <SubHeader title="Database Management" seperatorTitle="Primal Cut" />
      <div className="bg-white p-4 m-4 rounded-md mx-4 items-center">
        <input type="text" onChange={e => setFilter(e.target.value)} />
        <div className="flex justify-end">
          <Button onClick={() => setItem(newItem)}>Add New Primal Cut</Button>
        </div>
        <ModelTable
          model={{ name: 'Muscle' }}
          collection={collection}
          columns={columns}
          version={updatedOn}
          onViewRow={row => setItem({ ...newItem, ...row })}
          className="mt-4"
          query={() => {
            const query = db.collection(collection);
            if (filter) return query.where('meatcutCode', '==', filter);
            return query;
          }}
        />
        <Item item={item} close={() => setItem(null)} saved={() => setUpdatedOn(new Date())}></Item>
      </div>
    </>
  );
};
