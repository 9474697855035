import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useServices } from '@services';
import { Table, AsyncButton } from '@component';
import PulseLoader from 'react-spinners/PulseLoader';

export const ModelTable = ({ model, query, collection, onViewRow, columns, version, className }) => {
  const { db } = useServices();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();

  const deleteItem = id => {
    return db
      .collection(collection)
      .doc(id)
      .delete()
      .then(() => {
        toast.success(`${model.name} item deleted`);
      })
      .catch(err => {
        console.log('🚀 ~ file: ModelTable.jsx ~ line 21 ~ ModelTable ~ err', err);
        toast.error(`Unknown error deleting ${model.name} item`);
      });
  };

  const loadList = useCallback(() => {
    const loadListQuery = query
      ? query().get()
      : db
          .collection(collection)
          // .where('isActive', '==', true)
          .get();

    return loadListQuery
      .then(result => {
        setList(
          result.docs.map(doc => {
            return { ...doc.data(), id: doc.id };
          })
        );
      })
      .catch(err => {
        console.log('🚀 ~ file: ModelTable.jsx ~ line 38 ~ loadList ~ err', err);
        toast.error(`An error occured while loading the ${model.name} list.`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [db, collection, model.name, query]);

  useEffect(() => {
    loadList();
  }, [version, loadList]);

  const rowActions = row => (
    <div className="flex justify-center items-center">
      <button onClick={() => onViewRow(row.original)} className="text-primary-500 px-4">
        View
      </button>
      <AsyncButton onClick={() => deleteItem(row.original.id).then(() => loadList())} className="text-primary-500 px-4">
        Delete
      </AsyncButton>
    </div>
  );

  if (loading) return <PulseLoader></PulseLoader>;

  return <div className={className}>{list && <Table columns={columns} data={list} actions={rowActions} />}</div>;
};
