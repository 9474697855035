import * as React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import firebase from 'firebase/app';

import Loader from '../../component/common/Loader';
import CONSTANT from '../../constant';
// import { getUserDetail, updateUserDetail } from '../../action/UserAction';
// import { updateVendorDetail } from './../../action/VendorAction';
import { onChange, validateForm, setStorage } from '../../utils';
import { db } from '../../firebase/index';
import defaultProfile from './../../assets/images/default.jpg';
import cameraImage from './../../assets/images/camera.png';

class ProfileEdit extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      id: props.match.params.id,
      showLoader: false,
      detail: undefined,
      firstName: { name: 'firstName', value: '', error: '', isRequired: true },
      lastName: { name: 'lastName', value: '', error: '', isRequired: true },
      email: { name: 'email', value: '', error: '', isRequired: false },
      phone: { name: 'phone', value: '', error: '', isRequired: true },
    };
  }

  componentDidMount() {
    this.loadList();
  }

  public render() {
    const { showLoader } = this.state;
    return (
      <React.Fragment>
        {showLoader && <Loader />}
        {this.renderHeader()}
        {this.renderBody()}
      </React.Fragment>
    );
  }

  renderHeader = () => {
    return (
      <div className="kt-subheader   kt-grid__item" id="kt_subheader">
        <div className="kt-container  kt-container--fluid ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Edit My Profile</h3>
          </div>
        </div>
      </div>
    );
  };

  renderBody = () => {
    const { detail, firstName, lastName, email, phone } = this.state;
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="row">
          <div className="col-xl-8 col-lg-12 col-md-8 col-sm-8">
            <div className="card card-custom gutter-b card-stretch">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="row pr-4">
                      <div className="col-lg-4">
                        <div className="mt-3 ">
                          <div className="profile-pic">
                            <div className="tumbnail">
                              {detail && detail.profileUrl ? (
                                <img src={detail.profileUrl} alt="avatar" height="200" width="200" />
                              ) : (
                                <img src={defaultProfile} alt="avatar" height="200" width="200" />
                              )}
                              <div className="edit">
                                <img src={cameraImage} alt="img" />
                                <input type="file" accept="image/*" onChange={this.onProfileAdd} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-8">
                        <div className="row mt-3">
                          <div className="col-lg-6 form-group">
                            <label>First Name</label>
                            <input
                              type="text"
                              className={firstName.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                              placeholder="Enter first name"
                              name={firstName.name}
                              value={firstName.value}
                              onChange={this.onChange}
                            />
                          </div>
                          <div className="col-lg-6 form-group">
                            <label>Last Name</label>
                            <input
                              type="text"
                              className={lastName.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                              placeholder="Enter last name"
                              name={lastName.name}
                              value={lastName.value}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-6 form-group">
                            <label>Email</label>
                            <input
                              type="text"
                              className={email.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                              placeholder="Enter email"
                              name={email.name}
                              value={email.value}
                              onChange={this.onChange}
                              disabled
                            />
                          </div>
                          <div className="col-lg-6 form-group">
                            <label>Phone</label>
                            <input
                              type="text"
                              className={phone.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                              placeholder="Enter phone"
                              name={phone.name}
                              value={phone.value}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <button
                              onClick={this.onSubmit}
                              type="button"
                              className="btn btn-primary btn-sm btn-block p-2"
                            >
                              Save
                            </button>
                          </div>
                          <div className="col-md-6" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  private onChange = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;

    onChange(this, name, value);
  };

  loadList = async () => {
    const { id } = this.state;
    this.setState({ showLoader: true });
    const user = await db.collection(CONSTANT.collection.users).doc(`${id}`).get();
    if (!user.exists) return toast.error('User not found.');

    let userData = user.data();
    if (userData) {
      onChange(this, this.state.firstName.name, userData.firstName);
      onChange(this, this.state.lastName.name, userData.lastName);
      onChange(this, this.state.email.name, userData.email);
      onChange(this, this.state.phone.name, userData.mobile);
    }
    this.setState({ detail: userData, showLoader: false });
  };

  onProfileAdd = (e: any) => {
    const f = e.target.files;
    var file = f[0];
    if (file) {
      this.handleUploadProfile(file);
    }
    e.target.value = '';
  };

  handleUploadProfile = (file: any) => {
    this.setState({ showLoader: true });

    const extArray = file.name.split('.');
    const ext = extArray[extArray.length - 1];
    const originalFilename = extArray[0];
    let fileName = `${originalFilename}-${new Date().getTime()}.${ext}`;
    const fileDirectory = `${CONSTANT.fileDirectory.profilePicture}/${fileName}`;

    const uploadTask = firebase.app().storage().ref(fileDirectory).put(file);

    uploadTask.on(
      'state_changed',
      (snapshot: any) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        this.setState({ progress });
      },
      (error: any) => {
        console.log(error);
      },
      () => {
        firebase
          .app()
          .storage()
          .ref(CONSTANT.fileDirectory.profilePicture)
          .child(fileName)
          .getDownloadURL()
          .then(url => {
            this.setState({ profileURL: url }, () => {
              this.updateProfileImage();
            });
          });
      }
    );
  };

  updateProfileImage = async () => {
    const { id, profileURL } = this.state;

    const user = await db.collection(CONSTANT.collection.users).doc(`${id}`).get();
    if (!user.exists) return toast.error('User not found.');

    const updateObj = {
      profileUrl: profileURL,
    };

    this.setState({ showLoader: true });
    await db.collection(CONSTANT.collection.users).doc(`${id}`).set(updateObj, { merge: true });
    this.loadList();
    this.setState({ showLoader: false });
  };

  onSubmit = async (e: any) => {
    e.preventDefault();
    const { id } = this.state;

    if (validateForm(this)) {
      const user = await db.collection(CONSTANT.collection.users).doc(`${id}`).get();
      if (!user.exists) return toast.error('User not found.');

      const userData = user.data();

      this.setState({ showLoader: true });
      const model = {
        firstName: this.state.firstName.value,
        lastName: this.state.lastName.value,
        displayName: `${this.state.firstName.value} ${this.state.lastName.value}`,
        mobile: this.state.phone.value,
      };
      await db.collection(CONSTANT.collection.users).doc(`${id}`).set(model, { merge: true });
      setStorage(CONSTANT.keys.userDetail, {
        uid: userData.uid,
        displayName: `${model.firstName} ${model.lastName}`,
        email: userData.email,
        profileUrl: userData.profileUrl ? userData.profileUrl : '',
      });

      this.setState({ showLoader: false });
      toast.success('User Successfully updated.');
      window.location.href = CONSTANT.url.profile.replace(':id', id);
    }
  };
}

export default ProfileEdit;
