import { useRef, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { filter, sortBy } from 'lodash';
import PulseLoader from 'react-spinners/PulseLoader';

import {
  Modal,
  InputImage,
  FormInput,
  FormSelect,
  Label,
  Button,
  Input3DModel,
  InputImages,
  TertiaryButton,
} from '@component';
import { useServices } from '@services';
import {
  collection,
  carcasePath,
  skeletonPath,
  animalCollection,
  levelOneCollection,
  levelTwoCollection,
  levelThreeCollection,
  modelPath,
} from './constants';

export const Item = ({ item, close, saved }) => {
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('general');
  const [animalOptions, setAnimalOptions] = useState([]);
  const [levelOneOptions, setLevelOneOptions] = useState([]);
  const [levelTwoOptions, setLevelTwoOptions] = useState([]);

  const { db } = useServices();

  const validate = values => {
    const errors = {};
    if (!values.animalId) {
      errors.animalId = 'Required';
    }
    if (!values.levelOneId) {
      errors.levelOneId = 'Required';
    }
    if (!values.levelTwoId) {
      errors.levelTwoId = 'Required';
    }
    if (!values.meatcutName) {
      errors.meatcutName = 'Required';
    }
    if (!values.meatcutCode) {
      errors.meatcutCode = 'Required';
    }
    if (!values.meatcutDetail1) {
      errors.meatcutDetail1 = 'Required';
    }
    if (!values.meatcutDetail2) {
      errors.meatcutDetail2 = 'Required';
    }

    return errors;
  };

  const formikRef = useRef();

  useEffect(() => {
    setLoading(true);
    return Promise.all([
      db.list(animalCollection),
      db.list(levelOneCollection),
      db.list(levelTwoCollection),
      db.list(levelThreeCollection),
    ])
      .then(([animalOptions, levelOneOptions, levelTwoOptions]) => {
        setAnimalOptions(animalOptions);
        setLevelOneOptions(levelOneOptions.map(o => ({ ...o, name: o.name.split('$').join(': ') })));
        setLevelTwoOptions(levelTwoOptions.map(o => ({ ...o, name: o.name.split('$').join(': ') })));
      })
      .catch(err => {
        toast.error('An unknown error occured');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [db]);

  const update3DModel = (setFieldValue, file) => {
    setFieldValue('modelFile', file);
    // for compatibility
    setFieldValue('modelUrl', file.url);
    setFieldValue('fileSize', file.size);
  };

  return (
    <Modal
      visible={!!item}
      onClose={close}
      title={item && item.id ? 'View / Edit Meat Cut' : 'Add new Meat Cut'}
      className="text-black"
    >
      {loading ? (
        <PulseLoader></PulseLoader>
      ) : (
        <Formik
          innerRef={formikRef}
          initialValues={{
            ...item,
            meatcutDetail1: item?.meatcutDetail.split('$')[0],
            meatcutDetail2: item?.meatcutDetail.split('$')[1],
          }}
          validate={validate}
          onSubmit={(values, { setSubmitting }) => {
            const combinedDetail = `${values.meatcutDetail1}$${values.meatcutDetail2}`;
            db.save(collection, { ...values, meatcutDetail: combinedDetail })
              .then(() => {
                setSubmitting(false);
                toast.success('Meat Cut saved successfully');
                saved();
                close();
              })
              .catch(err => {
                console.log('🚀 ~ file: Item.jsx ~ line 122 ~ Item ~ err', err);
                toast.error('An unknown error occured');
              });
          }}
        >
          {({ values, isSubmitting }) => (
            <>
              <div className="pb-4 flex">
                <TertiaryButton
                  onClick={() => setSelectedTab('general')}
                  className={`${selectedTab === 'general' && 'bg-primary-500 text-white'} p-2`}
                >
                  General
                </TertiaryButton>
                <TertiaryButton
                  onClick={() => setSelectedTab('images')}
                  className={`${selectedTab === 'images' && 'bg-primary-500 text-white'} p-2 ml-4`}
                >
                  Images
                </TertiaryButton>
                <TertiaryButton
                  onClick={() => setSelectedTab('otherImages')}
                  className={`${selectedTab === 'otherImages' && 'bg-primary-500 text-white'} p-2 ml-4`}
                >
                  More Carcase Images
                </TertiaryButton>
              </div>
              <Form>
                {selectedTab === 'general' && (
                  <div className="space-y-4">
                    <div className="md:flex space-x-4">
                      <div className="md:w-6/12 space-y-4">
                        <div className="flex space-x-2">
                          <div className="w-3/12">
                            <Label htmlFor="meatcutCode"> Code * </Label>
                            <FormInput name="meatcutCode"></FormInput>
                          </div>
                          <div className="w-9/12">
                            <Label htmlFor="meatcutName">Name *</Label>
                            <FormInput name="meatcutName"></FormInput>
                          </div>
                        </div>

                        <div>
                          <Label htmlFor="levelOneId">Level 1 *</Label>
                          <FormSelect
                            name="levelOneId"
                            options={sortBy(levelOneOptions, ['name'])}
                            placeholder="Select Level 1"
                          ></FormSelect>
                        </div>

                        <div>
                          <Label htmlFor="levelTwoId">Level 2 *</Label>
                          <FormSelect
                            name="levelTwoId"
                            options={sortBy(levelTwoOptions, ['name'])}
                            placeholder="Select Level 2"
                          ></FormSelect>
                        </div>
                      </div>

                      <div className="md:w-6/12 space-y-4">
                        <div>
                          <Label htmlFor="animalId">Animal *</Label>
                          <FormSelect
                            name="animalId"
                            options={sortBy(animalOptions, ['name'])}
                            placeholder="Select Animal"
                          ></FormSelect>
                        </div>
                        <label htmlFor="modelUrl">3D Model File</label>
                        <Input3DModel
                          file={values.modelFile}
                          storagePath={modelPath}
                          onChange={modelFile => update3DModel(formikRef.current.setFieldValue, modelFile)}
                        ></Input3DModel>
                      </div>
                    </div>
                    <div className="flex">
                      <div className="w-6/12 pr-2">
                        <Label htmlFor="meatcutDetail1"> Detail 1 * </Label>
                        <FormInput as="textarea" name="meatcutDetail1" rows="8"></FormInput>
                      </div>

                      <div className="w-6/12 pl-2">
                        <Label htmlFor="meatcutDetail2"> Detail 2 * </Label>
                        <FormInput as="textarea" name="meatcutDetail2" rows="8"></FormInput>
                      </div>
                    </div>
                  </div>
                )}

                {selectedTab === 'images' && (
                  <div className="md:flex space-x-2">
                    <div className="md:w-6/12">
                      <label htmlFor="carcaseUrl" className="mt-4 md:mt-0">
                        Carcase Image
                      </label>
                      <InputImage
                        imageUrl={values.carcaseUrl}
                        storagePath={carcasePath}
                        onChange={url => formikRef.current.setFieldValue('carcaseUrl', url)}
                        height="350px"
                      ></InputImage>
                    </div>
                    <div className="md:w-6/12">
                      <label htmlFor="skeltonUrl" className="mt-4">
                        Skeleton Image
                      </label>
                      <InputImage
                        imageUrl={values.skeltonUrl}
                        storagePath={skeletonPath}
                        onChange={url => formikRef.current.setFieldValue('skeltonUrl', url)}
                        height="350px"
                      ></InputImage>
                    </div>
                  </div>
                )}
                {selectedTab === 'otherImages' && (
                  <>
                    <label htmlFor="carcaseImages" className="mt-4">
                      Carcase Images
                    </label>
                    <InputImages
                      images={values.carcaseImages}
                      storagePath={carcasePath}
                      onChange={image =>
                        formikRef.current.setFieldValue('carcaseImages', [...values.carcaseImages, image])
                      }
                      onRemoveImage={id =>
                        formikRef.current.setFieldValue(
                          'carcaseImages',
                          filter(values.carcaseImages, image => image.id !== id)
                        )
                      }
                    ></InputImages>
                  </>
                )}

                {/* <Label htmlFor="muscleRef" className="mt-4">
                  Muscle Ref Code *
                </Label>
                <FormInput name="muscleRef"></FormInput> */}

                <div className="flex justify-end">
                  <Button
                    type="submit"
                    disabled={!!isSubmitting}
                    className="btn btn-brand btn-elevate btn-icon-sm mt-12"
                  >
                    {isSubmitting ? <PulseLoader color="white" size={10}></PulseLoader> : 'Submit'}
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};
