import { useState, useMemo, useEffect } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { toast } from 'react-toastify';

import { ModelTable, Button, SubHeader } from '@component';

import {
  collection,
  animalCollection,
  levelOneCollection,
  levelTwoCollection,
  levelThreeCollection,
} from './constants';
import { Item } from './Item';
import { useServices } from '@services';

export const MeatcutList = () => {
  const { db } = useServices();
  const [item, setItem] = useState(null);
  const [updatedOn, setUpdatedOn] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [animals, setAnimalOptions] = useState([]);
  const [levelOneCategories, setLevelOneOptions] = useState([]);
  const [levelTwoCategories, setLevelTwoOptions] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterCode, setFilterCode] = useState('');

  const newItem = {
    animalId: '',
    levelOneId: '',
    levelTwoId: '',
    meatcutName: '',
    meatcutCode: '',
    meatcutDetail: '',
    meatcutDetail1: '',
    meatcutDetail2: '',
    modelUrl: '',
    modelFile: {
      name: '',
      size: '',
      url: '',
    },
    carcaseImages: [],
    skeltonUrl: '',
    carcaseUrl: '',
  };

  useEffect(() => {
    setLoading(true);
    return Promise.all([
      db.list(animalCollection),
      db.list(levelOneCollection),
      db.list(levelTwoCollection),
      db.list(levelThreeCollection),
    ])
      .then(([animalOptions, levelOneOptions, levelTwoOptions, levelThreeOptions]) => {
        setAnimalOptions(animalOptions);
        setLevelOneOptions(levelOneOptions);
        setLevelTwoOptions(levelTwoOptions);
      })
      .catch(err => {
        toast.error('An unknown error occured');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [db]);

  const columns = useMemo(
    () => [
      {
        Header: 'Image',
        accessor: ({ carcaseUrl, skeltonUrl }) => {
          const url = carcaseUrl || skeltonUrl;
          if (!url) return;
          return (
            <div className="flex justify-center">
              <img src={url} alt="Visual representation" height="60" width="90" />
            </div>
          );
        },
        id: 'image',
      },
      { Header: 'Code', accessor: 'meatcutCode' },
      { Header: 'Name', accessor: 'meatcutName' },
      {
        Header: 'Animal',
        accessor: ({ animalId }) => {
          const category = animals.find(cat => {
            return cat.id === animalId;
          });
          return category ? category.name : '';
        },
        id: 'animalId',
      },
      {
        Header: 'Level 1',
        accessor: ({ levelOneId }) => {
          const category = levelOneCategories.find(cat => {
            return cat.id === levelOneId;
          });
          return category ? category.name.split('$').join(': ') : '';
        },
        id: 'levelOneId',
      },
      {
        Header: 'Level 2',
        accessor: ({ levelTwoId }) => {
          const category = levelTwoCategories.find(cat => cat.id === levelTwoId);
          return category ? category.name.split('$').join(': ') : '';
        },
        id: 'levelTwo',
      },
    ],
    [levelOneCategories, levelTwoCategories, animals]
  );

  return (
    <>
      <SubHeader title="Database Management" seperatorTitle="Meat Product" />
      <div className="bg-white p-4 m-4 rounded-md mx-4 items-center">
        {loading ? (
          <PulseLoader></PulseLoader>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <div className="space-x-4">
                <input
                  type="text"
                  onChange={e => setFilterName(e.target.value)}
                  className="border border-gray-100 rounded-md p-2"
                  placeholder="Search Name"
                />
                <input
                  type="text"
                  onChange={e => setFilterCode(e.target.value)}
                  className="border border-gray-100 rounded-md p-2"
                  placeholder="Search Code"
                />
              </div>

              <Button onClick={() => setItem(newItem)}>Add Meat Product</Button>
            </div>
            <ModelTable
              model={{ name: 'Meat Product' }}
              collection={collection}
              columns={columns}
              version={updatedOn}
              onViewRow={row => setItem({ ...newItem, ...row })}
              className="mt-4"
              query={() => {
                const query = db.collection(collection);
                if (filterCode) return query.where('meatcutCode', '==', filterCode);
                if (filterName) return query.where('meatcutName', '==', filterName);
                return query;
              }}
            />
          </>
        )}
        <Item item={item} close={() => setItem(null)} saved={() => setUpdatedOn(new Date())}></Item>
      </div>
    </>
  );
};
