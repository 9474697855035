import * as React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import Loader from '../../../component/common/Loader';
import SubHeader from '../../../component/common/SubHeader';
import AddIndustryComponentDetail from '../../../component/cms-pages/industryOverviewDetail';
import CONSTANT from '../../../constant';
import { db } from '../../../firebase/index';

class AddFoodIntegrity extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showLoader: false,
    };
  }

  public render() {
    const { showLoader } = this.state;
    return (
      <React.Fragment>
        {showLoader && <Loader />}
        <SubHeader headerTitle="CMS Pages" seperatorTitle="" />
        {this.renderBody()}
      </React.Fragment>
    );
  }

  renderBody = () => {
    return (
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="row">
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Add Food Safety Integrity & Traceability</h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <AddIndustryComponentDetail onSubmit={this.onSubmit} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  onSubmit = async (model: any) => {
    this.setState({ showLoader: true });
    model.modifiedOn = moment().unix();
    model.isActive = true;
    model.type = 'food';
    await db.collection(CONSTANT.collection.industryOverview).add(model);
    this.setState({ showLoader: false });
    window.location.href = CONSTANT.url.foodIntegrity;
  };
}

export default AddFoodIntegrity;
