import { useRef } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import PulseLoader from 'react-spinners/PulseLoader';

import { Modal, InputImage, InputPdf, FormInput, Label, Button } from '@component';
import { useServices } from '@services';
import { collection, thumbnailPath, fileDirectory } from './constants';

export const Item = ({ item, close, saved }) => {
  const { db } = useServices();

  const validate = values => {
    const errors = {};
    if (!values.title) {
      errors.title = 'Required';
    }
    if (!values.country) {
      errors.country = 'Required';
    }
    if (!values.code) {
      errors.code = 'Required';
    }
    if (!values.file || !values.file.url) {
      errors.file = 'Required';
    }

    return errors;
  };

  const formikRef = useRef();

  const updatePdf = (setFieldValue, file) => {
    setFieldValue('file', file);
    // for compatibility
    setFieldValue('pdfUrl', file.url);
    setFieldValue('fileSize', file.size);
  };

  return (
    <Modal
      visible={!!item}
      onClose={close}
      title={item && item.id ? 'View / Edit PDF' : 'Add new PDF'}
      className="text-black"
    >
      <Formik
        innerRef={formikRef}
        initialValues={item}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          db.save(collection, values)
            .then(() => {
              setSubmitting(false);
              toast.success('PDF saved successfully');
              saved();
              close();
            })
            .catch(() => {
              toast.error('An unknown error occured');
            });
        }}
      >
        {({ values, isSubmitting }) => {
          values.file = {
            url: values.file.url || values.pdfUrl,
            name: values.file.name || values.pdfFileName,
            size: values.file.size || values.fileSize,
          };
          return (
            <>
              <Form className="space-y-4">
                <div className="flex space-x-2">
                  <div className="w-3/12">
                    <Label htmlFor="code">Code *</Label>
                    <FormInput name="code"></FormInput>
                  </div>
                  <div className="w-9/12">
                    <Label htmlFor="title">Title *</Label>
                    <FormInput name="title"></FormInput>
                  </div>
                </div>
                <div>
                  <Label htmlFor="country">Country *</Label>
                  <FormInput name="country"></FormInput>
                </div>
                <label>PDF</label>
                <div className="flex space-x-2">
                  <div className="w-6/12">
                    <label htmlFor="file">File *</label>
                    <InputPdf
                      file={values.file}
                      storagePath={fileDirectory}
                      onChange={file => updatePdf(formikRef.current.setFieldValue, file)}
                    ></InputPdf>
                    <ErrorMessage name="file" component="div" className="text-error-200" />
                  </div>
                  <div className="w-6/12">
                    <label htmlFor="thumbnail">Thumbnail</label>
                    <InputImage
                      imageUrl={values.thumbnail}
                      storagePath={thumbnailPath}
                      onChange={url => formikRef.current.setFieldValue('thumbnail', url)}
                      width="350px"
                    ></InputImage>
                  </div>
                </div>

                <div className="flex justify-end">
                  <Button
                    type="submit"
                    disabled={!!isSubmitting}
                    className="btn btn-brand btn-elevate btn-icon-sm mt-4"
                  >
                    {isSubmitting ? <PulseLoader color="white" size={10}></PulseLoader> : 'Submit'}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};
