import { useRef } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import { Modal, FormInput, Label, AsyncButton } from '@component';
import { useServices } from '@services';
import { collection, pageName } from './constants';

export const PageModal = ({ page, close, saved, type }) => {
  const { db } = useServices();

  const validate = values => {
    const errors = {};
    if (!values.title) {
      errors.title = 'Required';
    }

    return errors;
  };

  const formikRef = useRef();

  return (
    <Modal
      visible={!!page}
      onClose={close}
      title={page && page.id ? `View / Edit ${pageName[type]} Page` : `Add new ${pageName[type]} page`}
      className="text-black"
    >
      <Formik
        innerRef={formikRef}
        initialValues={{ ...page, type }}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          db.save(collection, values)
            .then(() => {
              setSubmitting(false);
              toast.success(`${pageName[type]} page saved successfully`);
              saved();
              close();
            })
            .catch(err => {
              toast.error('An unknown error occured');
            });
        }}
      >
        {({ isSubmitting }) => (
          <>
            <Form>
              <Label htmlFor="title">Title *</Label>
              <FormInput name="title" />

              <Label htmlFor="description" className="mt-4">
                Description
              </Label>
              <FormInput name="description" as="textarea" rows={10} />

              <Label htmlFor="order" className="mt-4">
                Order
              </Label>
              <FormInput name="order" type="number" />

              <div className="flex justify-end">
                <AsyncButton
                  type="submit"
                  isSubmitting={isSubmitting}
                  className="btn btn-brand btn-elevate btn-icon-sm mt-4"
                >
                  Submit
                </AsyncButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
};
