import { useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import PulseLoader from 'react-spinners/PulseLoader';

import { Modal, InputImage, FormInput, Label, Button, FormSelect } from '@component';
import { useServices } from '@services';
import { collection, productPath, carcaseCollection, carcaseSubCollection } from './constants';

export const Item = ({ item, close, saved }) => {
  const { db } = useServices();
  const [loading, setLoading] = useState(false);
  const [carcaseCategoryOptions, setCarcaseCategoryOptions] = useState([]);
  const [carcaseSubcategoryOptions, setSubCategoryOptions] = useState([]);

  const validate = values => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.code) {
      errors.code = 'Required';
    }
    if (!values.description) {
      errors.description = 'Required';
    }
    if (!values.categoryCypher) {
      errors.categoryCypher = 'Required';
    }

    return errors;
  };

  useEffect(() => {
    setLoading(true);
    return Promise.all([db.list(carcaseCollection), db.list(carcaseSubCollection)])
      .then(([carcaseOptions, subCategoryOptions]) => {
        setCarcaseCategoryOptions(carcaseOptions);
        setSubCategoryOptions(subCategoryOptions);
      })
      .catch(err => {
        toast.error('An unknown error occured');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [db]);

  const formikRef = useRef();

  return (
    <Modal
      visible={!!item}
      onClose={close}
      title={item && item.id ? 'View / Edit Dentition' : 'Add new Dentition'}
      className="text-black"
    >
      {loading ? (
        <PulseLoader></PulseLoader>
      ) : (
        <Formik
          validateOnBlur
          validateOnChange
          innerRef={formikRef}
          initialValues={item}
          validate={validate}
          onSubmit={(values, { setSubmitting }) => {
            db.save(collection, values)
              .then(() => {
                setSubmitting(false);
                toast.success('Dentition saved successfully');
                saved();
                close();
              })
              .catch(err => {
                toast.error(err);
              });
          }}
        >
          {({ values, isSubmitting }) => (
            <>
              <Form>
                <div className="md:flex">
                  <div className="md:w-6/12 md:pr-4 space-y-4">
                    <div className="flex space-x-2">
                      <div className="w-3/12">
                        <Label htmlFor="code">Code *</Label>
                        <FormInput name="code"></FormInput>
                      </div>
                      <div className="w-9/12">
                        <Label htmlFor="name">Age *</Label>
                        <FormInput name="name"></FormInput>
                      </div>
                    </div>

                    <div>
                      <Label htmlFor="carcaseCategoryId" className="mt-4">
                        Carcase Category *
                      </Label>
                      <FormSelect
                        name="carcaseCategoryId"
                        options={carcaseCategoryOptions}
                        placeholder="Select a Carcase Category"
                      />
                    </div>

                    <div>
                      <Label htmlFor="carcaseSubCategoryId">Carcase Subcategory *</Label>
                      <FormSelect
                        name="carcaseSubCategoryId"
                        options={carcaseSubcategoryOptions}
                        placeholder="Select a carcase subcategory"
                      />
                    </div>

                    <div>
                      <Label htmlFor="description">Description *</Label>
                      <FormInput as="textarea" name="description" rows="5"></FormInput>
                    </div>

                    <div>
                      <Label htmlFor="categoryCypher">Cypher *</Label>
                      <FormInput name="categoryCypher"></FormInput>
                    </div>
                  </div>
                  <div className="md:w-6/12 md:pl-4">
                    <label htmlFor="productUrl">Product Image</label>
                    <InputImage
                      imageUrl={values.productUrl}
                      storagePath={productPath}
                      onChange={url => formikRef.current.setFieldValue('productUrl', url)}
                    ></InputImage>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button
                    type="submit"
                    disabled={!!isSubmitting}
                    className="btn btn-brand btn-elevate btn-icon-sm mt-4"
                  >
                    {isSubmitting ? <PulseLoader color="white" size={10}></PulseLoader> : 'Submit'}
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};
