import { BrowserRouter } from 'react-router-dom';
import { Router } from './Router';
import { ServiceProvider } from '@services';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ServiceProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      <ToastContainer position="top-right" autoClose={3000} pauseOnHover />
    </ServiceProvider>
  );
}

export default App;
