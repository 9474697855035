import LoginBG from '@assets/images/landing_page_ausmeat.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { AsyncButton } from '@component';

export const ForgotPassword = () => {
  const validate = values => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  const sendResetEmail = () => {};

  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root h-screen">
        <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{ backgroundImage: `url(${LoginBG})` }}
            >
              <div className="kt-grid__item">
                {/* <a href="#" className="kt-login__logo">
                                        <img src={Logo4} />
                                    </a> */}
              </div>
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver"></div>
              <div className="kt-grid__item">
                <div className="kt-login__info">
                  <div className="kt-login__copyright">
                    Copyright © {new Date().getFullYear()} <b>Aus Meat</b>. All rights reserved.
                  </div>
                </div>
              </div>
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <div className="kt-login__body">
                <div className="kt-login__form">
                  <div className="kt-login__title">
                    <h3>Forgot password</h3>
                  </div>

                  <Formik initialValues={{ email: '' }} validate={validate} onSubmit={sendResetEmail}>
                    {({ values, isSubmitting }) => (
                      <>
                        <Form>
                          <Field className="login-input w-full" type="email" name="email" placeholder="Email" />
                          <ErrorMessage className="text-error-200" name="email" component="div" />

                          <AsyncButton type="submit" className="btn btn-brand btn-elevate btn-icon-sm mt-12 w-full">
                            Send Reset Email
                          </AsyncButton>
                          <div className="form-group flex justify-center align-items-center mt-12">
                            <a href="/login" className="kt-link kt-login__link-forgot">
                              Back to Login
                            </a>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
