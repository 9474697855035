import PulseLoader from 'react-spinners/PulseLoader';

export const Card = ({ className, loading, children }) => {
  return (
    <div className={`bg-white rounded shadow-sm p-6 ${className}`}>
      {loading ? (
        <div className="flex flex-col justify-center h-full min-h-16">
          <PulseLoader size={5} />
        </div>
      ) : (
        children
      )}
    </div>
  );
};
