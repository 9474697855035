// import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CONSTANT from '../constant';
// import { getStorage } from './utils';
import { DefaultLayout } from './layout';

import { Dashboard } from './dashboard';
import FourZeroFour from './FourZeroFour';

import { UserManagementList } from './user-management/List';
import { MeatcutList } from './db-management/meatcut/List';
import { PrimalCutList } from './db-management/primalcut/List';
import { DentitionList } from './db-management/dentition/List';
import { PackagingList } from './db-management/packaging/List';
import { CheckList } from './db-management/checklist/List';

import { PdfManagementList } from './pdf-management/List';

import MyProfile from './my-profile/Profile';
import ProfileEdit from './my-profile/ProfileEdit';

import CarcaseList from './cms-pages/carcase/List';
import AddCarcase from './cms-pages/carcase/Add';
import EditCarcase from './cms-pages/carcase/Edit';
// import AboutUs from './cms-pages/about-aus-meat/List';
// import AddAboutUs from './cms-pages/about-aus-meat/Add';
// import EditAboutUs from './cms-pages/about-aus-meat/Edit';
import TermCondition from './cms-pages/Terms';
import { CategoryManagement } from './cms-pages/Category';
import { IndustryOverview } from './cms-pages/IndustryOverview';
import FaqList from './cms-pages/faq/List';
import AddFaq from './cms-pages/faq/Add';
import EditFaq from './cms-pages/faq/Edit';
import FoodIntegrityList from './cms-pages/food-integrity/List';
import AddFoodIntegrity from './cms-pages/food-integrity/Add';
import EditFoodIntegrity from './cms-pages/food-integrity/Edit';
import MarketingResearchList from './cms-pages/marketing-research/List';
import AddMarketingResearch from './cms-pages/marketing-research/Add';
import EditMarketingResearch from './cms-pages/marketing-research/Edit';

export const PortalRouter = (props: any) => {
  return (
    <DefaultLayout>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/user" component={UserManagementList} />

        {/* database management routes */}
        <Route exact path="/database/meatcut" component={MeatcutList} />
        <Route exact path="/database/primalcut" component={PrimalCutList} />
        <Route exact path="/database/dentition" component={DentitionList} />
        <Route exact path="/database/packaging" component={PackagingList} />

        <Route exact path="/database/checklist" component={CheckList} />

        {/* database management routes */}

        <Route exact path="/pdf-management" component={PdfManagementList} />

        {/* profile routes */}
        <Route exact path={CONSTANT.url.profile} component={MyProfile} />
        <Route exact path={CONSTANT.url.profileEdit} component={ProfileEdit} />
        {/* profile routes */}

        {/* CMS pages routes */}
        <Route exact path={CONSTANT.url.carcaseList} component={CarcaseList} />
        <Route exact path={CONSTANT.url.addCarcase} component={AddCarcase} />
        <Route exact path={CONSTANT.url.editCarcase} component={EditCarcase} />
        {/* <Route exact path={CONSTANT.url.aboutUs} component={AboutUs} />
        <Route exact path={CONSTANT.url.addAboutUs} component={AddAboutUs} />
        <Route exact path={CONSTANT.url.editAboutUs} component={EditAboutUs} /> */}
        <Route exact path={CONSTANT.url.termCondition} component={TermCondition} />
        <Route exact path={'/category/animal-type'} component={CategoryManagement} />
        <Route exact path={'/category/meat-code'} component={CategoryManagement} />
        <Route exact path={'/category/muscle'} component={CategoryManagement} />
        <Route exact path={'/category/level-1-category'} component={CategoryManagement} />
        <Route exact path={'/category/level-2-category'} component={CategoryManagement} />
        <Route exact path={'/category/carcase-category'} component={CategoryManagement} />
        <Route exact path={'/category/carcase-subcategory'} component={CategoryManagement} />
        <Route exact path={'/cms-pages/about'} component={IndustryOverview} />
        <Route exact path={'/cms-pages/food'} component={IndustryOverview} />
        <Route exact path={'/cms-pages/marketing'} component={IndustryOverview} />
        <Route exact path={CONSTANT.url.faq} component={FaqList} />
        <Route exact path={CONSTANT.url.addFaq} component={AddFaq} />
        <Route exact path={CONSTANT.url.editFaq} component={EditFaq} />
        <Route exact path={CONSTANT.url.foodIntegrity} component={FoodIntegrityList} />
        <Route exact path={CONSTANT.url.addFoodIntegrity} component={AddFoodIntegrity} />
        <Route exact path={CONSTANT.url.editFoodIntegrity} component={EditFoodIntegrity} />
        <Route exact path={CONSTANT.url.marketingResearch} component={MarketingResearchList} />
        <Route exact path={CONSTANT.url.addMarketingResearch} component={AddMarketingResearch} />
        <Route exact path={CONSTANT.url.editMarketingResearch} component={EditMarketingResearch} />
        {/* CMS pages routes */}
        <Route exact path="/login">
          <Redirect to="/"></Redirect>
        </Route>
        <Route component={FourZeroFour} />
      </Switch>
    </DefaultLayout>
  );
};
