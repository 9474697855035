import { useState, useMemo } from 'react';

import { useServices } from '@services';
import { Button, ModelTable } from '@component';
import { PageModal } from './PageModal';
import { collection, pageName } from './constants';

export const IndustryOverviewList = ({ type }) => {
  const { db } = useServices();

  const [page, setPage] = useState(null);
  const [updatedOn, setUpdatedOn] = useState(new Date());

  const columns = useMemo(
    () => [
      { Header: 'Title', accessor: 'title' },
      {
        Header: 'Description',
        accessor: ({ description }) => {
          if (!description) return '';
          const splitDescription = description.split(' ');
          return `${splitDescription.slice(0, 100).join(' ')}${splitDescription.length > 100 ? '...' : ''}`;
        },
      },
      { Header: 'Order', accessor: 'order' },
    ],
    []
  );

  return (
    <div className="bg-white p-4 rounded-md items-center">
      <div className="flex justify-end">
        <Button onClick={() => setPage({})}>Add New</Button>
      </div>
      <ModelTable
        model={{ name: pageName[type] }}
        columns={columns}
        query={() => db.collection(collection).where('type', '==', type).orderBy('order', 'asc')}
        collection={collection}
        version={updatedOn}
        onViewRow={setPage}
      />
      <PageModal page={page} type={type} close={() => setPage(null)} saved={() => setUpdatedOn(new Date())} />
    </div>
  );
};
