import * as React from 'react';
import errorBG from '@assets/media/error/bg4.jpg';

function FourZeroFour() {
  return (
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div
        className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v4"
        style={{ backgroundImage: `url(${errorBG})` }}
      >
        <div className="kt-error_container">
          <h1 className="kt-error_number">404</h1>
          <p className="kt-error_title">ERROR</p>
          <a href="/" className="kt-error_description">
            Back to Home
          </a>
        </div>
      </div>
    </div>
  );
}

export default FourZeroFour;
