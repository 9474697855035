import { useState } from 'react';
import { Button } from '@component';
import { useServices } from 'services';
import { toast } from 'react-toastify';

const ComponentName = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { auth } = useServices();
  const ud = auth.currentUser;

  const logout = () =>
    auth
      .signOut()
      .catch(err => {
        toast.error('An error occured while logging out');
      })
      .finally(() => {});
  return (
    <div style={{ borderBottom: '2px solid #90caf9' }}>
      <div className="bg-white flex justify-between md:justify-end p-2">
        <button onClick={() => setShowDropdown(!showDropdown)} className="avatar avatar-sm w-12 h-12">
          {ud && ud.profileUrl ? (
            <img className="rounded-circle" src={ud.profileUrl} alt="" />
          ) : (
            <img className="rounded-circle" src="/assets/media/users/default.jpg" alt="" />
          )}
        </button>
        <button
          onClick={() => setShowDropdown(!showDropdown)}
          className="kt-header__topbar-username kt-hidden-mobile ml-2 h-12"
        >
          {ud && ud.displayName ? ud.displayName : ud.email}
        </button>
      </div>
      <div>
        {showDropdown && (
          <div className="fixed z-20 p-4 bg-white shadow-md" style={{ top: '3rem', right: '3rem' }}>
            <Button className="w-full" onClick={logout}>
              Log Out
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentName;
