import { useState, useCallback } from 'react';
import { useServices } from 'services';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PulseLoader from 'react-spinners/PulseLoader';

import { Button } from '@component';

export const LoginForm = () => {
  const { auth } = useServices();
  const [error, setError] = useState('');

  const login = useCallback(
    ({ email, password }, { setSubmitting }) => {
      setError('');
      auth
        .signInWithEmailAndPassword(email, password)
        .catch(err => {
          setError(err.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [auth]
  );

  const validate = values => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    return errors;
  };

  return (
    <div className="w-full" style={{ maxWidth: '450px' }}>
      <h3 className="font-medium text-3xl mb-8">Log In</h3>

      <Formik initialValues={{ email: '', password: '' }} validate={validate} onSubmit={login}>
        {({ values, isSubmitting }) => (
          <>
            <Form>
              <Field className="login-input w-full" type="email" name="email" placeholder="Email" />
              <ErrorMessage className="text-error-200" name="email" component="div" />

              <Field className="login-input mt-4 w-full" type="password" name="password" placeholder="Password" />
              <ErrorMessage className="text-error-200" name="password" component="div" />

              <Button
                type="submit"
                disabled={!!isSubmitting}
                className="btn btn-brand btn-elevate btn-icon-sm mt-12 w-full"
              >
                {isSubmitting ? <PulseLoader color="white" size={10}></PulseLoader> : 'Log In'}
              </Button>
              <div className="form-group flex justify-center align-items-center mt-12">
                <a href="/forgot-password" className="kt-link kt-login__link-forgot">
                  Forgot Password?
                </a>
              </div>
            </Form>
          </>
        )}
      </Formik>
      {error && <div className="text-error-200">{error}</div>}
    </div>
  );
};
