import { useState, useCallback } from 'react';
import { useServices } from 'services';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { NavLink, useHistory } from 'react-router-dom';

import { AsyncButton } from '@component';

export const ResetPasswordForm = ({ email, actionCode }) => {
  const { auth } = useServices();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const resetPassword = useCallback(
    ({ passwordConfirm }, { setSubmitting }) => {
      auth
        .confirmPasswordReset(actionCode, passwordConfirm)
        .then(() => {
          setSuccess(true);
          auth.signInWithEmailAndPassword(email, passwordConfirm).then(() => history.push('/'));
        })
        .catch(error => {
          setSuccess(false);
          setError(error.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [auth, actionCode, email, history]
  );

  const validate = values => {
    const errors = {};
    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Required';
    }
    if (values.passwordConfirm !== values.password) {
      errors.passwordConfirm = 'Password does not match';
    }
    return errors;
  };

  return (
    <div className="w-full" style={{ maxWidth: '450px' }}>
      <h3 className="font-medium text-3xl mb-8">Reset Password</h3>
      <Formik initialValues={{ password: '', passwordConfirm: '' }} validate={validate} onSubmit={resetPassword}>
        {() => (
          <>
            <Form>
              <Field className="login-input w-full" type="password" name="password" placeholder="Password" />
              <ErrorMessage className="text-error-200" name="password" component="div" />
              <Field
                className="login-input w-full"
                type="password"
                name="passwordConfirm"
                placeholder="Password Confirmation"
              />
              <ErrorMessage className="text-error-200" name="passwordConfirm" component="div" />
              <AsyncButton type="submit" className="btn btn-brand btn-elevate btn-icon-sm mt-12 w-full">
                Submit
              </AsyncButton>
            </Form>
          </>
        )}
      </Formik>
      {!!error && <div className="text-error-200 pt-4">{error}</div>}
      {!!success && (
        <div className="text-primary pt-4">
          <div>Your password has been successfully reset. Redirecting...</div>
          <div className="mt-2">
            If you are waiting too long, please click&nbsp;
            <NavLink to="/login" className="underline">
              here
            </NavLink>
            &nbsp;to login
          </div>
        </div>
      )}
    </div>
  );
};
