import { Field, ErrorMessage } from 'formik';

export const FormSelect = ({ name, options, placeholder, className }) => {
  const hideDropdownArrow = { '-moz-appearance': 'none', '-webkit-appearance': 'none', appearance: 'none' };
  return (
    <div className={className}>
      <Field as="select" name={name} className="border border-gray-100 rounded-md p-2 w-full" style={hideDropdownArrow}>
        <option value="">{placeholder}</option>
        {options.map((item, index) => {
          return (
            <option key={`${item.id}-${index}`} id={item.id} value={item.id}>
              {item.name}
            </option>
          );
        })}
      </Field>
      <ErrorMessage name={name} component="div" className="text-error-200" />
    </div>
  );
};
