import { useState, useMemo } from 'react';

import { ModelTable, Button, SubHeader } from '@component';

import { collection } from './constants';
import { Item } from './Item';
const prettyBytes = require('pretty-bytes');

export const PdfManagementList = () => {
  const [item, setItem] = useState(null);
  const [updatedOn, setUpdatedOn] = useState(new Date());

  const newItem = {
    title: '',
    country: '',
    code: '',
    file: {
      url: '',
      size: 0,
      name: '',
    },
    pdfUrl: '',
  };

  const columns = useMemo(
    () => [
      { Header: 'Code', accessor: 'code' },
      { Header: 'Title', accessor: 'title' },
      { Header: 'File Size', accessor: row => (row.fileSize ? prettyBytes(row.fileSize) : 0), id: 'fileSize' },
      { Header: 'Country', accessor: 'country' },
    ],
    []
  );

  return (
    <>
      <SubHeader title="PDF Management"></SubHeader>
      <div className="bg-white p-4 m-4 rounded-md mx-4 items-center">
        <div className="flex justify-end">
          <Button onClick={() => setItem(newItem)}>Add New PDF</Button>
        </div>
        <ModelTable
          model={{ name: 'PDF' }}
          collection={collection}
          columns={columns}
          version={updatedOn}
          onViewRow={row => setItem({ ...newItem, ...row })}
          className="mt-4"
        />
        <Item item={item} close={() => setItem(null)} saved={() => setUpdatedOn(new Date())}></Item>
      </div>
    </>
  );
};
