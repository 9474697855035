import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import PulseLoader from 'react-spinners/PulseLoader';
import { map, omit } from 'lodash';

import { useServices } from '@services';
import { Table } from '@component';

export const UsersTable = ({ onViewRow, columns, version, className }) => {
  const { functions } = useServices();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();

  const deleteUser = uid => {
    setLoading(true);
    const deleteUserFunction = functions.httpsCallable('api/users/delete');
    return deleteUserFunction(uid)
      .then(() => {
        toast.success(`User Deleted`);
      })
      .catch(err => {
        toast.error(`Unknown error deleting User`);
      })
      .finally(() => {
        loadUsers();
        setLoading(false);
      });
  };

  const loadUsers = useCallback(() => {
    setLoading(true);
    const getUserListFunction = functions.httpsCallable('api/users/getList');
    getUserListFunction()
      .then(({ data }) => {
        console.log('🚀 ~ file: Table.jsx ~ line 35 ~ .then ~ data', data);
        setUsers(
          map(data.users, u => ({
            ...omit(u, ['customClaims']),
            role: u.customClaims?.role || '',
          }))
        );
      })
      .catch(err => toast.error)
      .finally(() => {
        setLoading(false);
      });
  }, [functions]);

  useEffect(() => {
    loadUsers();
  }, [version, loadUsers]);

  const rowActions = row => (
    <div className="flex justify-center items-center">
      <button onClick={() => onViewRow(row.original)} className="text-primary-500 px-4">
        View
      </button>
      <button onClick={() => deleteUser(row.original.uid)} className="text-primary-500 px-4">
        Delete
      </button>
    </div>
  );

  if (loading) return <PulseLoader></PulseLoader>;

  return <div className={className}>{users && <Table columns={columns} data={users} actions={rowActions} />}</div>;
};
