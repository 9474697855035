import { useState, useMemo } from 'react';

import { ModelTable, Button } from '@component';

import { collection } from './constants';
import { Item } from './Item';

export const MuscleCode = ({ className }) => {
  const [item, setItem] = useState(null);
  const [updatedOn, setUpdatedOn] = useState(new Date());

  const columns = useMemo(
    () => [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Code', accessor: 'code' },
    ],
    []
  );

  const newItem = {
    name: '',
    code: '',
  };

  return (
    <div className={className}>
      <div className="flex justify-between">
        <h3 className="text-lg font-bold text-black">Muscles</h3>
        <Button onClick={() => setItem(newItem)}>Add Muscle</Button>
      </div>
      <ModelTable
        model={{ name: 'Muscle' }}
        collection={collection}
        columns={columns}
        version={updatedOn}
        onViewRow={row => setItem({ ...newItem, ...row })}
        className="mt-4"
      />
      <Item item={item} close={() => setItem(null)} saved={() => setUpdatedOn(new Date())}></Item>
    </div>
  );
};
