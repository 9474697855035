import { LoginForm } from './Form';
import { Footer } from './Footer';
import LoginBG from '@assets/images/landing_page_ausmeat.png';

export const Login = () => {
  return (
    <div className="lg:flex lg:fixed h-24 lg:h-screen w-full">
      <div className="hidden lg:block lg:relative h-full lg:w-6/12">
        <div className="absolute inset-0 scrim"></div>
        <Footer className="absolute bottom-0 inset-x-0"></Footer>
        <div
          className="w-full h-full bg-no-repeat bg-cover items-end flex justify-center"
          style={{ backgroundImage: `url(${LoginBG})` }}
        ></div>
      </div>
      <div className="lg:w-6/12 h-full bg-white">
        <div className="h-full flex justify-center items-center p-4 lg:p-0">
          <LoginForm></LoginForm>
        </div>
      </div>
    </div>
  );
};
