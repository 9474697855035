import { useRef, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { split } from 'lodash';

import { Modal, FormInput, Label, AsyncButton } from '@component';
import { useServices } from '@services';
import { collection } from './constants';

export const Item = ({ item, close, saved }) => {
  const { db } = useServices();

  const validate = values => {
    const errors = {};
    if (!values.animal) {
      errors.animal = 'Required';
    }
    if (!values.category) {
      errors.category = 'Required';
    }

    return errors;
  };

  const formikRef = useRef();

  const convertedItem = useMemo(() => {
    if (!item) return {};
    const animal = split(item.name, '$')[0] || '';
    const category = split(item.name, '$')[1] || '';
    return {
      ...item,
      animal,
      category,
    };
  }, [item]);

  return (
    <Modal
      visible={!!item}
      onClose={close}
      title={item && item.id ? 'View / Edit Level 2 Category' : 'Add new Level 2 Category'}
      className="text-black"
    >
      <Formik
        innerRef={formikRef}
        initialValues={convertedItem}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          const formattedValues = { ...values, name: `${values.animal}$${values.category}` };
          delete formattedValues.animal;
          delete formattedValues.category;
          db.save(collection, formattedValues)
            .then(() => {
              setSubmitting(false);
              toast.success('Level 2 category saved successfully');
              saved();
              close();
            })
            .catch(() => {
              toast.error('An unknown error occured');
            });
        }}
      >
        {({ values, isSubmitting }) => (
          <>
            <Form>
              <div className="mb-3">
                <Label htmlFor="animal">Animal *</Label>
                <FormInput name="animal"></FormInput>
              </div>
              <div>
                <Label htmlFor="category">Category *</Label>
                <FormInput name="category"></FormInput>
              </div>

              <div className="flex justify-end">
                <AsyncButton type="submit" className="btn btn-brand btn-elevate btn-icon-sm mt-4">
                  Submit
                </AsyncButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
};
