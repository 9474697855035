import { useState, useMemo } from 'react';
import { replace } from 'lodash';
import { ModelTable, Button } from '@component';

import { collection } from './constants';
import { Item } from './Item';

export const LevelTwoList = ({ className }) => {
  const [item, setItem] = useState(null);
  const [updatedOn, setUpdatedOn] = useState(new Date());

  const columns = useMemo(
    () => [{ Header: 'Name', accessor: 'name', Cell: props => replace(props.value, /\$/g, ': ') }],
    []
  );

  const newItem = {
    name: '',
  };

  return (
    <div className={className}>
      <div className="flex justify-between">
        <h3 className="text-lg font-bold text-black">Level 2 Category</h3>

        <Button onClick={() => setItem(newItem)}>Add Level 2 Category</Button>
      </div>
      <ModelTable
        model={{ name: 'Level 2' }}
        collection={collection}
        columns={columns}
        version={updatedOn}
        onViewRow={row => setItem({ ...newItem, ...row })}
        className="mt-4"
      />
      <Item item={item} close={() => setItem(null)} saved={() => setUpdatedOn(new Date())}></Item>
    </div>
  );
};
