import { SubHeader } from '@component';

import { TotalUsers, MeatcutCount } from './Statistics';

export const Dashboard = () => {
  const StatisticWrapper = ({ children }) => (
    <div className="w-1/3">
      <div className="mx-2 mb-4">{children}</div>
    </div>
  );

  return (
    <>
      <SubHeader title="Dashboard" />
      <div className="flex flex-wrap px-4">
        <StatisticWrapper>
          <TotalUsers />
        </StatisticWrapper>
        <StatisticWrapper>
          <MeatcutCount />
        </StatisticWrapper>
      </div>
    </>
  );
};

export default Dashboard;
