import { Field, ErrorMessage } from 'formik';

export const FormInput = ({ as = 'input', type = 'text', name, rows, className, minHeight }) => {
  return (
    <div className={className}>
      <Field
        as={as}
        type={type}
        name={name}
        rows={rows}
        className="border border-gray-100 rounded-md p-2 w-full"
        style={{ minHeight: minHeight }}
      />
      <ErrorMessage name={name} component="div" className="text-error-200" />
    </div>
  );
};
