import { toast } from 'react-toastify';
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useState } from 'react';
import { useServices } from '@services';

import { isolateFile, timestampFilename } from './uploadHelpers';
import DropzonePdf from './DropzonePdf';
import { Progress } from './Progress';

export const InputPdf = ({ file = {}, storagePath, onChange, className }) => {
  const { storage } = useServices();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploadPdf = files => {
    setLoading(true);
    const _file = isolateFile(files);
    if (!_file) {
      toast.error('File not found');
      return;
    }
    const nameStamp = timestampFilename(_file);
    const uploadTask = storage.ref(`${storagePath}/${nameStamp}`).put(_file);

    uploadTask.catch(err => {
      console.log('🚀 ~ file: InputPdf.jsx ~ line 25 ~ uploadTask ~ err', err);
      toast.error(err);
    });

    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);
      },
      err => {
        console.log('🚀 ~ file: InputPdf.jsx ~ line 33 ~ InputPdf ~ err', err);
        toast.error('An unknown error occured while uploading');
        setLoading(false);
      },
      () => {
        storage
          .ref(storagePath)
          .child(nameStamp)
          .getDownloadURL()
          .then(url => {
            onChange({ url, size: _file.size, name: _file.name });
            toast.success('PDF upload was successful');
          })
          .catch(err => {
            toast.error("An unknown error occured while loading your PDF's URL");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    );
  };

  const confirmDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to remove this pdf?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'No, keep it',
    }).then(result => {
      if (result.value) {
        deleteFile();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your pdf is safe :)', 'error');
      }
    });
  };

  const deleteFile = () => {
    setLoading(true);
    const ref = storage.refFromURL(file.url);

    storage
      .ref(storagePath)
      .child(ref.name)
      .delete()
      .then(url => {
        onChange({ url: '', size: 0, name: '' });
      })
      .catch(() => {
        toast.error('An error has occured while removing your thumbnail.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <Progress completed={progress}></Progress>;
  }

  return (
    <div className={className}>
      {file.url ? (
        <div>
          <a href={file.url} target="_blank" rel="noreferrer">
            <i className="kt-nav__link-icon text-primary fas fa-file-pdf mr-2"></i>
            {file.name}
          </a>
          <button
            onClick={confirmDelete}
            type="button"
            className="btn btn-secondary btn-sm block kt-mt-10"
            aria-label="Delete Thumbnail"
          >
            Remove File
          </button>
        </div>
      ) : (
        <DropzonePdf acceptFile={uploadPdf} />
      )}
    </div>
  );
};
