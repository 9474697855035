import Header from './Header';
import Footer from './Footer';
import { Sidebar } from './Sidebar';

export const DefaultLayout = ({ children }) => {
  return (
    <div>
      <div className="w-full h-full flex">
        <Sidebar />
        <div className="w-full md:ml-sidebar flex flex-col h-screen">
          <div>
            <Header />
          </div>
          <div className="flex-auto">{children}</div>
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
