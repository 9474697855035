import { useRef, useEffect, useState, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import PulseLoader from 'react-spinners/PulseLoader';
import { sortBy } from 'lodash';
import ReactSelect from 'react-select';

import { Modal, InputImage, FormInput, Label, Button, Input3DModel } from '@component';
import { useServices } from '@services';
import { collection, photoRefPath, muscleCategoryCollection, modelPath } from './constants';
import { ReactComponent as Close } from 'assets/icons/close.svg';

export const Item = ({ item, close, saved }) => {
  const [loading, setLoading] = useState(false);

  const [muscleCategoryOptions, setMuscleCategoryOptions] = useState([]);

  const { db } = useServices();

  const validate = values => {
    const errors = {};
    if (!values.meatcut) {
      errors.meatcut = 'Required';
    }
    if (!values.meatcutCode) {
      errors.meatcutCode = 'Required';
    }

    return errors;
  };

  const formikRef = useRef();

  useEffect(() => {
    setLoading(true);
    return db
      .list(muscleCategoryCollection)
      .then(muscleCategoryOptions => {
        setMuscleCategoryOptions(muscleCategoryOptions.map(o => ({ value: o.id, label: o.name })));
      })
      .catch(err => {
        toast.error('An unknown error occured');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [db]);

  const update3DModel = (setFieldValue, file) => {
    setFieldValue('modelFile', file);
    // for compatibility
    setFieldValue('modelUrl', file.url);
    setFieldValue('fileSize', file.size);
  };

  const getMuscleName = useCallback(
    muscleId => muscleCategoryOptions.find(({ value }) => value === muscleId)?.label || 'No name found',
    [muscleCategoryOptions]
  );

  return (
    <Modal
      visible={!!item}
      onClose={close}
      title={item && item.id ? 'View / Edit Primal Cut' : 'Add new Primal Cut'}
      className="text-black"
    >
      {loading ? (
        <PulseLoader></PulseLoader>
      ) : (
        <Formik
          innerRef={formikRef}
          initialValues={{
            carcasePercentage: '',
            view: '',
            tag: '',
            weightRange1: '',
            weightRange2: '',
            weightRange3: '',
            weightRange4: '',
            muscleIds: [],
            ...item,
          }}
          validate={validate}
          onSubmit={(values, { setSubmitting }) => {
            db.save(collection, values)
              .then(() => {
                setSubmitting(false);
                toast.success('Primal Cut saved successfully');
                saved();
                close();
              })
              .catch(() => {
                toast.error('An unknown error occured');
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <>
              <Form>
                <div className="md:flex">
                  <div className="md:w-6/12 pr-4 space-y-4">
                    <div className="flex space-x-2">
                      <div className="w-3/12">
                        <Label htmlFor="meatcutCode"> Code * </Label>
                        <FormInput name="meatcutCode"></FormInput>
                      </div>
                      <div className="w-9/12">
                        <Label htmlFor="meatcut"> Name * </Label>
                        <FormInput name="meatcut"></FormInput>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <div className="w-3/12">
                        <Label htmlFor="view"> View </Label>
                        <FormInput name="view"></FormInput>
                      </div>
                      <div className="w-9/12">
                        <Label htmlFor="tag"> Tag </Label>
                        <FormInput name="tag"></FormInput>
                      </div>
                    </div>
                    <div>
                      <Label htmlFor="carcasePercentage">Carcase %</Label>
                      <FormInput name="carcasePercentage"></FormInput>
                    </div>
                    <div className="md:flex md:space-x-2">
                      <div className="md:w-6/12">
                        <Label htmlFor="weightRange1">160-180kg</Label>
                        <FormInput name="weightRange1" />
                      </div>
                      <div className="md:w-6/12">
                        <Label htmlFor="weightRange2">180-220kg</Label>
                        <FormInput name="weightRange2" />
                      </div>
                    </div>
                    <div className="md:flex md:space-x-2">
                      <div className="md:w-6/12">
                        <Label htmlFor="weightRange3">220-260kg</Label>
                        <FormInput name="weightRange3" />
                      </div>
                      <div className="md:w-6/12">
                        <Label htmlFor="weightRange4">260-300kg</Label>
                        <FormInput name="weightRange4" />
                      </div>
                    </div>
                    <div>
                      <Label htmlFor="muscleIds">Assigned Muscles</Label>
                      <ReactSelect
                        value=""
                        isOptionDisabled={({ value }) => values.muscleIds.includes(value)}
                        options={sortBy(muscleCategoryOptions, ['name'])}
                        placeholder="Select a muscle"
                        onChange={({ value }) => {
                          setFieldValue('muscleIds', [...values.muscleIds, value]);
                        }}
                      />
                      <div className="mt-2 flex flex-wrap min-h-20 max-h-36 overflow-y-scroll items-start">
                        {values.muscleIds.map(muscleId => (
                          <button
                            type="button"
                            className="px-3 py-1 h-8 rounded-full bg-primary-500 text-white mr-2 my-1 flex items-center text-sm"
                            onClick={() => {
                              setFieldValue(
                                'muscleIds',
                                values.muscleIds.filter(assignedMuscleId => assignedMuscleId !== muscleId)
                              );
                            }}
                          >
                            {getMuscleName(muscleId)}
                            <Close className="w-4 h-4 ml-3" />
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="md:w-6/12 pl-4 space-y-4">
                    <div>
                      <label htmlFor="photoRefUrl" className="mt-4">
                        Reference Photo
                      </label>
                      <InputImage
                        imageUrl={values.photoRefUrl}
                        storagePath={photoRefPath}
                        onChange={url => formikRef.current.setFieldValue('photoRefUrl', url)}
                        width="350px"
                      ></InputImage>
                    </div>
                    <div>
                      <label htmlFor="modelUrl">3D Model File</label>
                      <Input3DModel
                        file={values.modelFile}
                        storagePath={modelPath}
                        onChange={modelFile => update3DModel(formikRef.current.setFieldValue, modelFile)}
                      ></Input3DModel>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end">
                  <Button
                    type="submit"
                    disabled={!!isSubmitting}
                    className="btn btn-brand btn-elevate btn-icon-sm mt-12"
                  >
                    {isSubmitting ? <PulseLoader color="white" size={10}></PulseLoader> : 'Submit'}
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </Modal>
  );
};
