import { useRef, useState, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';

import { Modal, FormInput, Label, Button } from '@component';
import { useServices } from '@services';
import PulseLoader from 'react-spinners/PulseLoader';

export const Item = ({ item, close, saved }) => {
  const { functions } = useServices();
  const [error, setError] = useState('');

  const validate = values => {
    const errors = {};
    if (!values.displayName) {
      errors.displayName = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    }
    if (!values.uid && !values.password) {
      errors.password = 'Required';
    }

    return errors;
  };

  const createUser = useCallback(
    newUser => {
      const createUserFunction = functions.httpsCallable('api/users/create');
      createUserFunction(newUser)
        .then(() => {
          close();
          saved();
        })
        .catch(err => setError(err.message));
    },
    [functions, close, saved]
  );

  const saveUser = useCallback(
    newUser => {
      if (!newUser.password) delete newUser.password;
      const updateUserFunction = functions.httpsCallable('api/users/update');
      updateUserFunction(newUser)
        .then(() => {
          close();
          saved();
        })
        .catch(err => setError(err.message));
    },
    [functions, close, saved]
  );

  const formikRef = useRef();

  return (
    <Modal
      visible={!!item}
      onClose={close}
      title={item && item.uid ? 'View / Edit User' : 'Add new User'}
      className="text-black"
    >
      <Formik
        innerRef={formikRef}
        initialValues={{ role: 'viewer', ...item }}
        validate={validate}
        onSubmit={(user, { setSubmitting }) => {
          user?.uid ? saveUser(user) : createUser(user);
        }}
      >
        {({ values, isSubmitting }) => (
          <>
            <Form>
              <Label htmlFor="displayName">Display Name *</Label>
              <FormInput name="displayName"></FormInput>

              <Label htmlFor="password" className="mt-4">
                {item?.uid ? 'Change Password' : 'Password *'}
              </Label>
              <FormInput name="password" type="password"></FormInput>

              <Label htmlFor="email" className="mt-4">
                Email *
              </Label>
              <FormInput name="email" type="email"></FormInput>

              {/* <Label htmlFor="phoneNumber" className="mt-4">
                Phone No.
              </Label>
              <FormInput name="phoneNumber"></FormInput> */}

              {item?.uid && (
                <>
                  <Label htmlFor="role" className="mt-4">
                    Role
                  </Label>
                  <div className="flex items-center">
                    <Field type="radio" name="role" value="admin" className="mr-2"></Field>
                    <div>Admin</div>
                  </div>
                  <div className="flex items-center">
                    <Field type="radio" name="role" value="editor" className="mr-2"></Field>
                    <div>Editor</div>
                  </div>
                  <div className="flex items-center">
                    <Field type="radio" name="role" value="viewer" className="mr-2"></Field>
                    <div>Viewer</div>
                  </div>
                </>
              )}

              <div className="flex justify-end">
                <Button type="submit" disabled={!!isSubmitting} className="btn btn-brand btn-elevate btn-icon-sm mt-4">
                  {isSubmitting ? <PulseLoader color="white" size={10}></PulseLoader> : 'Submit'}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
      {!!error && (
        <div className="text-error-200 pt-4 text-center">
          {error === 'internal' ? 'An unexpeced error occured' : error}
        </div>
      )}
    </Modal>
  );
};
