import { useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

export const AsyncButton = ({ onClick, children, className, type }) => {
  const [loading, setLoading] = useState(false);

  const action = () => {
    if (!onClick) return;
    setLoading(true);
    onClick().finally(() => {
      setLoading(false);
    });
  };

  return (
    <button className={className} disabled={!!loading} onClick={action} type={type}>
      {loading ? <PulseLoader color="white" size={10}></PulseLoader> : children}
    </button>
  );
};
