import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useServices } from 'services';
import { ResetPasswordForm } from './Form';
import { Footer } from './Footer';
import ResetPasswordBG from '@assets/images/landing_page_ausmeat.png';

export const ResetPassword = () => {
  const { auth } = useServices();
  const [email, setEmail] = useState(false);
  const actionCode = new URLSearchParams(useLocation().search).get('oobCode');
  const [verifying, setVerifying] = useState(true);

  useEffect(() => {
    setVerifying(true);
    if (!actionCode) return setVerifying(false);
    auth
      .verifyPasswordResetCode(actionCode)
      .then(email => {
        setEmail(email);
      })
      .catch(() => {
        setEmail();
      })
      .finally(() => {
        setVerifying(false);
      });
  }, [auth, actionCode]);

  return (
    <div className="lg:flex lg:fixed h-24 lg:h-screen w-full">
      <div className="hidden lg:block lg:relative h-full lg:w-6/12">
        <div className="absolute inset-0 scrim"></div>
        <Footer className="absolute bottom-0 inset-x-0" />
        <div
          className="w-full h-full bg-no-repeat bg-cover items-end flex justify-center"
          style={{ backgroundImage: `url(${ResetPasswordBG})` }}
        />
      </div>
      <div className="lg:w-6/12 h-full bg-white">
        <div className="h-full flex justify-center items-center p-4 lg:p-0">
          {verifying && <div className="text-3xl"> Loading... </div>}
          {!verifying && email && <ResetPasswordForm email={email} actionCode={actionCode} />}
          {!verifying && !email && (
            <div>
              <h1 className="text-3xl pb-4 w-full text-center">Try resetting your password again</h1>
              <div className="text-xl">
                Your request to reset your password has expired or the link has already been used
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
