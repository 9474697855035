import { useState, useMemo } from 'react';
import { format } from 'date-fns';

import { Button, SubHeader } from '@component';

import { Item } from './Item';
import { UsersTable } from './Table';

export const UserManagementList = () => {
  const [item, setItem] = useState(null);
  const [updatedOn, setUpdatedOn] = useState(new Date());

  const newItem = {
    email: '',
    role: '',
    displayName: '',
    password: '',
  };

  const columns = useMemo(
    () => [
      { Header: 'Name', accessor: 'displayName' },
      // {
      //   Header: 'Phone No.',
      //   accessor: 'phoneNumber',
      // },
      { Header: 'Email', id: 'email', accessor: ({ email }) => <a href={`mailto:${email}`}>{`${email}`}</a> },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Last Active',
        id: 'lastActive',
        accessor: ({ metadata }) => format(new Date(metadata.lastSignInTime), 'Pp'),
      },
    ],
    []
  );

  return (
    <>
      <SubHeader title="User Management"></SubHeader>
      <div className="bg-white p-4 m-4 rounded-md mx-4 items-center">
        <div className="flex justify-end">
          <Button onClick={() => setItem(newItem)}>Add New User</Button>
        </div>
        <UsersTable
          columns={columns}
          version={updatedOn}
          onViewRow={row => setItem({ ...newItem, ...row })}
          className="mt-4"
        />
        <Item item={item} close={() => setItem(null)} saved={() => setUpdatedOn(new Date())}></Item>
      </div>
    </>
  );
};
