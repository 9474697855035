import { useRef } from 'react';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import { Modal, FormInput, Label, AsyncButton } from '@component';
import { useServices } from '@services';
import { collection } from './constants';

export const Item = ({ item, close, saved }) => {
  const { db } = useServices();

  const validate = values => {
    const errors = {};
    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.code) {
      errors.code = 'Required';
    }

    return errors;
  };

  const formikRef = useRef();

  return (
    <Modal
      visible={!!item}
      onClose={close}
      title={item && item.id ? 'View / Edit Muscle' : 'Add new Muscle'}
      className="text-black"
    >
      <Formik
        innerRef={formikRef}
        initialValues={item}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          db.save(collection, values)
            .then(() => {
              setSubmitting(false);
              toast.success('Saved successfully');
              saved();
              close();
            })
            .catch(() => {
              toast.error('An unknown error occured');
            });
        }}
      >
        {() => (
          <>
            <Form>
              <Label htmlFor="name">Name *</Label>
              <FormInput name="name"></FormInput>

              <Label htmlFor="code" className="mt-4">
                Code *
              </Label>
              <FormInput name="code"></FormInput>

              <div className="flex justify-end">
                <AsyncButton type="submit" className="btn btn-brand btn-elevate btn-icon-sm mt-4">
                  Submit
                </AsyncButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
};
