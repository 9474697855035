import { useState, useCallback, useEffect } from 'react';

import { Card } from '@component';
import { useServices } from '@services';

export const MeatcutCount = () => {
  const { db } = useServices();

  const [loading, setLoading] = useState(false);
  const [meatcutCount, setMeatcutCount] = useState(0);

  const getMeatcutCount = useCallback(() => {
    setLoading(true);
    return db.list('meatcut').then(meatcutList => {
      setMeatcutCount(meatcutList?.length || 0);
      setLoading(false);
    });
  }, [db]);

  useEffect(() => getMeatcutCount(), [getMeatcutCount]);

  return (
    <Card loading={loading} className="text-center">
      <div className="mb-4">{meatcutCount ? meatcutCount : '-'}</div>
      <div>Total No. of Meatcuts</div>
    </Card>
  );
};
