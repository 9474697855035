export const Button = ({ type, onClick, children, className, disabled }) => {
  return (
    <button
      type={type}
      className={`${className} btn btn-brand btn-elevate btn-icon-sm`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
